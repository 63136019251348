<template>
    <section class="hero forms-hero is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only">
        <div class="hero-body">
            <section class="section">
                <div class="container">
                    <div class="columns">
                        <div class="column is-6">
                            <div class="deposit-products-box">
                            <p class="title hero-title">
                                Forms
                            </p>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    </section>

    <div class="blue-bg is-hidden-widescreen">
        <img src="https://assets.webbank.com/forms-hero2.png">
        <section class="section">
            <div class="container">
                <p class="title hero-title-mobile">
                    Forms
                </p>
            </div>
        </section>
    </div>

    <section class="section">
        <div class="container">
            <p class="is-size-4">Check out our list of online forms below. Can&apos;t find what you need?
                Reach out to a WebBank representative
                for additional assistance at 844-994-2265 from 8:00 AM to 5:00 PM Mountain Time.</p>
            <br>
            <br>
            <div class="columns">
                <div class="column is-half">
                    <a href="https://assets.webbank.com/forms/ach-stop-payment-form.pdf"
                        target="_blank" class="button is-primary is-fullwidth is-large-desktop">ACH Stop Payment
                        Form</a>
                    <br>
                    <a href="https://assets.webbank.com/forms/webBank-esign-agreement.pdf"
                        target="_blank" class="button is-primary is-fullwidth is-large-desktop">eConsent</a>
                    <br>
                    <a href="https://assets.webbank.com/forms/terms-and-conditions.pdf"
                        target="_blank" class="button is-primary is-fullwidth is-large-desktop">Terms and Conditions</a>
                    <br>
                    <a href="https://assets.webbank.com/forms/wire-transfer-request-form.pdf"
                        target="_blank" class="button is-primary is-fullwidth is-large-desktop">Wire Transfer Request
                        Form</a>
                    <br>
                    <a href="https://assets.webbank.com/forms/webbank_privacy_072018.pdf"
                        target="_blank" class="button is-primary is-fullwidth is-large-desktop">Privacy Notice</a>
                    <br>
                    <a href="https://assets.webbank.com/forms/written-statement-of-unauthorize-ach-debit.pdf"
                        target="_blank" class="button is-primary is-fullwidth is-large-desktop">Written Statement of
                        Unauthorized ACH Debit</a>
                    <br>
                </div>
                <div class="column has-text-centered">
                    <div class="box wb-box">
                        <br>
                        <br>
                        <span class="icon diff-icon"><i class="fa-light fa-hand-holding-dollar fa-6x"></i></span>
                        <br>
                        <br>
                        <h4 class="box-title">Watch Your Money Grow</h4>
                        <br>
                        <p class="is-size-5">Get the most out of your account with our high returns.</p>
                        <br>
                        <p>
                            <router-link to="/rates-fees">Check Out Our Deposit Rates</router-link>
                        </p>
                        <br>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <br>
</template>

<script>
export default {

    created() {
        document.title = "Forms - WebBank";
    },

}
</script>
