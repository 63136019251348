<template>
    <div class="columns is-mobile">
        <div class="column is-4">
            <figure class="image">
            <img src="https://assets.webbank.com/people-asadi3.png" class="is-rounded" alt="Eraj Asadi">
            </figure>
        </div>
        <div class="column">
            <h2 class="people-name">Eraj Asadi</h2>
            <h3 class="people-title">SVP Head of Asset Finance</h3>
            <p class="people-bio">
Eraj is SVP, Head of Asset Finance of WebBank and responsible for the expansion, management and strategy for the Bank's asset finance platform, encompassing both existing Strategic Partner opportunities as well as third-party specialty finance lenders.  Eraj's 30-year career has been solely focused on asset/structured finance, commencing with early sell-side experience within the asset finance teams at firms including CS First Boston and Lehman Brothers.  From 1999 until 2012, Eraj built and ran Rabobank International's North American asset finance business.  His subsequent experience has been primarily in private credit at firms including Perella, Weinberg Partners and Brevet Capital, each of whom focused on lower-middle market asset-backed lending opportunities.  Eraj brings a wealth of experience to WebBank given his involvement in this market through up and down cycles, and from both sell- and buy-side perspectives.  Eraj received a B.S. and M.B.A. from New York University.
            </p>
        </div>
    </div>
</template>

<script>
export default {

}
</script>
