<template>

<a name="california-employee-policy" id="california-employee-policy">&nbsp;</a>

<div style="margin-top: 60px;">
        <p class="is-pulled-right mt-10"><router-link :to="{ name: 'PrivacyPage', hash: '#top' }"><i class="fa-solid fa-arrow-up"></i> Back to Top</router-link></p>
        <h4 class="has-text-primary">CALIFORNIA EMPLOYEE PRIVACY POLICY</h4>
<br>
    <p>WebBank and its affiliated entities (“Bank”) are committed to respecting your privacy
    and protecting your personal information. This California Employee Privacy Policy
    applies to any of our employees, contractors, job applicants, prospective employees,
    and former employees who are California residents and about whom we have collected
    personal information for employment-related purposes only, including in the course of
    employment or application for employment with us (collectively, “<strong>you</strong>”).</p>
    <br>
    <p>To learn more about our privacy practices with respect to personal information collected
    about consumers and other individuals for purposes unrelated to employment, please
    refer to our <router-link :to="{ name: 'PrivacyPage', hash: '#top' }">Privacy Policy</router-link>.</p>
    <br>
    <p>We provide this California Employee Privacy Policy to comply with applicable privacy
    laws, including the California Consumer Privacy Act of 2018 and as amended by the
    California Privacy Rights Act of 2020 (together, the “<strong>CCPA</strong>”). The CCPA provides
    California residents with rights to receive certain disclosures regarding the collection,
    use, and sharing of personal information, as well as rights to access, correct, delete,
    and restrict the sale, use, and disclosure of certain personal information we collect
    about them, unless exceptions apply. California residents also have the right not to
    receive discriminatory treatment by us for the exercise of your privacy rights under the
    CCPA. If you are a California resident, you may submit a request to exercise these
    rights by calling us at 1-884-994-BANK (2265), or emailing us at
    <a href="mailto:WebBankHR@webbank.com">WebBankHR@webbank.com</a>, or <router-link :to="{ name: 'ContactUsPage' }">completing our online webform</router-link> using the information
    provided in the <router-link :to="{ name: 'PrivacyPage', hash: '#ca-privacy-rights' }">Your Privacy Rights</router-link> section below.</p>
    <br>
    <p>For the purposes of this California Employee Privacy Policy, except where a different
    definition is noted, “<strong>personal information</strong>” means information that identifies, relates to,
    describes, is reasonably capable of being associated with, or could reasonably be
    linked, directly or indirectly, with a particular California resident or household. Personal
    information does not include publicly available information; lawfully obtained, truthful
    information that is a matter of public concern; information that has been de-identified or
    aggregated; or other information covered by certain sector-specific privacy laws, as
    consistent with state law. Any capitalized term used and not otherwise defined below
    has the meaning assigned to it in our <strong>Privacy Policy</strong>.</p>
    <br>
    <p>If you are a visually impaired individual, an individual with another disability, or an
    individual seeking support in another language, you may access this California
    Employee Privacy Policy by emailing us at <a href="mailto:WebBankHR@webbank.com">WebBankHR@webbank.com</a>.</p>
        <br>
    <h4 class="pp-section-title">1. Personal Information We Collect</h4>
    <p>Bank may collect, or has collected, the following categories of personal information over
    the past twelve (12) months:</p>
    <ol type="a">
        <li><strong>Identifiers</strong>, which includes first and last name; home address; personal email
    address; telephone number; employee or personnel number; other account or
    user identification information; driver’s license information; license plate
    information; and emergency contact details.</li>
        <li><strong>Professional or employment-related information</strong>, which includes job
    application details; current employment; employment history; salary, wages, and
    other compensation data; benefits and entitlement data; bonus payments and
    amounts; company or entity; business unit or division; employment contract
    information; employment start date; employment end date; hours of work; job title
    or role; manager/supervisor; office location; employee key card or access card
    use data; travel booking details; travel history; performance ratings; professional
    memberships; background check references and related information; military
    service records and related documentation; vehicle make and model; and any
    disciplinary action and/or reason for termination.</li>
        <li><strong>Education information</strong>, which includes academic transcripts; awards or honors;
    degrees earned; education and training history; grades; languages spoken; and
    other qualifications and certifications.</li>
        <li><strong>Personal information described in California Civil Code § 1798.80(e)</strong>, which
    includes bank account numbers, credit or debit card numbers, and other financial
    information; insurance policy numbers, medical conditions, workplace
    accommodations and related documentation, FMLA calculations and related
    leave records, worker’s compensation claim information, and other medical and
    health insurance information.</li>
        <li><strong>Characteristics protected under California or federal law</strong>, which includes
    your date of birth, age, or age range; criminal history; criminal records; disability
    status; drug test results; gender or gender identity; income level; marital status;
    military or veteran status; nationality; pregnancy status; racial or ethnic origin;
    sexual orientation; trade union membership; as well as similar information about
    your household members and dependents.</li>
        <li><strong>Internet or other electronic network activity information</strong>, which includes
    cookie identifiers, your computer’s operating system.</li>
        <li><strong>Geolocation data, Audio, electronic, visual, thermal, olfactory, or similar
    information</strong>.</li>
        <li><strong>Inferences</strong> drawn from the personal information listed above.</li>
    </ol>
    <br>
    <p>Please note that because of the overlapping nature of certain of the categories of
    personal information identified above, which are required by state law, some of the
    personal information we collect may be reasonably classified under multiple categories.</p>
        <br>
    <p><strong>Sensitive personal information</strong>. Certain of the personal information that we collect,
    as described above, may constitute “sensitive personal information” under California
    law, including:</p>
    <ul>
        <li>Social Security number, driver’s license, state identification card, or passport
    number</li>
    </ul>
    <br>
        <h4 class="pp-section-title">2. Sources of Personal Information</h4>
    <p>We may collect personal information about you from the following categories of sources:</p>
    <ul>
        <li><strong>From you</strong>, including from your employment application and/or over the course of
    your employment, such as in-person or via email, telephone, electronic
    messaging, postal mail, social media, or other means.</li>
        <li><strong>From other individuals</strong>, including recruiters and temporary job placement
    agencies, and our other employees and contractors.</li>
        <li><strong>From our service providers</strong>, including providers of background checks and
    credit checks; communications and analytics providers; internet service
    providers; operating systems and platforms; data brokers; and fraud solutions.</li>
        <li><strong>From government entities</strong>, to assist in providing employee benefits and
    services to you.</li>
    </ul>
    <p>We may supplement the information described above with information we obtain from
    other sources, including from both online and offline data providers.</p>
    <br>
    <h4 class="pp-section-title">3. How We Use Your Personal Information</h4>
    <p>We may use the personal information we collect from you for the following purposes:</p>
    <ul>
        <li>To facilitate employment and internship applications, including conducting
        screenings, interviews, and background checks.</li>
        <li>To facilitate employee training.</li>
        <li>To communicate with our employees.</li>
        <li>To manage our workforce, including to complete performance reviews,
        evaluations, and internal investigations.</li>
        <li>To administer payroll and benefits to our employees and their dependents.</li>
        <li>To provide you with services, products, discounts, and other benefits related to
        your status as an employee.</li>
        <li>To operate our systems, including IT, maintenance, and security.</li>
        <li>To limit, protect, or otherwise restrict physical access to our offices and facilities,
        including areas with sensitive data.</li>
        <li>To maintain and improve the quality of our employee sites, services, and
        benefits.</li>
        <li>To grow our business, including to perform research and development, and
        understand the effectiveness of our marketing.</li>
        <li>To ensure legal compliance, manage risk, and audit our business, including to
        respond to subpoenas, court orders, and legal process, or to establish or
        exercise our legal rights or defend against legal claims; and</li>
        <li>To meet our obligations for taxes and other government filing requirements.</li>
    </ul>
    <p>The sensitive personal information that we collect as described in Section 1 above may
    be used for any of the above purposes.</p>
    <br>
    <p>We will retain personal information for as long as needed or permitted in light of the
    purpose(s) for which it was obtained. The criteria used to determine our retention
    periods include: (i) the length of time we have an ongoing relationship with you; (ii)
    whether there is a legal obligation to which we are subject; (iii) whether there is a
    privacy right for which the personal information has been exercised (such as a request
    to delete); and (iv) whether retention is advisable in light of our legal position (such as in
    regard to applicable statutes of limitations, litigation or regulatory investigations).</p>
    <br>
    <h4 class="pp-section-title">4. Who We Disclose Your Personal Information To</h4>
    <p>We do not sell or share your personal information as those terms are defined under
    California law. We may disclose your personal information with the following categories
    of service providers and third parties:</p>
    <ul>
        <li><strong>Our other employees</strong>. We may disclose your personal information with our
        other employees consistent with the purposes identified in Section 2 above.</li>
        <li><strong>Our affiliates</strong>. We may disclose your personal information with our affiliates
        consistent with the purposes identified in Section 2 above.</li>
        <li><strong>Service providers</strong>. We may disclose your personal information with our service
        providers to provide services on our behalf, such as providers of background and
        credit checks, HR and payroll processing, analytics, hosting, technical support,
        and other services. These third parties have access to your personal information
        only to perform these tasks on our behalf and are obligated not to disclose or use
        the information for any other purpose.</li>
        <li><strong>Affiliate and business transfer</strong>. If Bank is involved in a merger, acquisition or
        asset sale, your personal information may be transferred. We will provide notice
        before your personal information is transferred and becomes subject to a
        different privacy policy.</li>
        <li><strong>Legal compliance and harm prevention</strong>. We may disclose your personal
        information as we believe necessary (i) to comply with applicable law, rules and
        regulations; (ii) to enforce our contractual rights; (iii) to investigate possible
        wrongdoing in connection with our company; (iv) to protect and defend the rights,
        privacy, safety and property of Bank, you or others; and (v) to respond to
        requests from courts, law enforcement agencies, regulatory agencies, and other
        public and government authorities.</li>
    </ul>
    <br>
    <a name="ca-privacy-rights" id="section-ca-privacy-rights">&nbsp;</a>
    <h4 class="pp-section-title" style="margin-top: 50px;">5. Your Privacy Rights</h4>
    <p>If you are a California resident, you have the right to make the following requests under
    applicable California law in relation to your personal information, subject to certain
    exceptions:</p>
    <ul>
        <li><strong>Right to Know</strong>. You have the right to, up to twice in a 12-month period, request
        what personal information we collect, use, disclose, and/or sell, and to whom, as
        applicable.</li>
        <li><strong>Right to Delete</strong>. You have the right to request, under certain circumstances, the
        deletion of your personal information that we collect.</li>
        <li><strong>Right to Opt-Out of Sale or Sharing</strong>. You have the right to opt-out of the sale
        or sharing of your personal information as those terms are defined under
        California law. Please note that Bank does not currently sell or share personal
        information that is subject to this California Employee Privacy Policy.</li>
        <li><strong>Right to Non-Discrimination</strong>. You have the right not to receive discriminatory
        treatment for the exercise of the privacy rights described above.</li>
        <li><strong>Right to Limit Use and Disclosure</strong>. You have the right to limit the use or
        disclosure of your sensitive personal information to only the uses necessary for
        us to provide employment-related services to you. We will not use or disclose
        your sensitive personal information after you have exercised your rights unless
        you subsequently provide consent for the use of your sensitive personal
        information for additional purposes.</li>
        <li><strong>Right to Correct</strong>. You have the right to request the correction of your inaccurate
        personal information.</li>
    </ul>
    <p><strong>How to submit a request</strong>. You may exercise any of the rights described in this section
    by emailing us at <a href="mailto:WebBankHR@webbank.com">WebBankHR@webbank.com</a>, or by calling 1-884-994-BANK (2265).</p>
    <br>
    <p>Any request you submit to us is subject to an identification and residency verification
    process (“<strong>Verifiable Consumer Request</strong>”) as permitted by the CCPA. We will not fulfill
    your request unless you have provided sufficient information that enables us to
    reasonably verify that you are the consumer about whom we collected the personal
    information on. To verify you, you must provide us with first name, last name, and email
    address. These rights are also subject to various exclusions and exceptions under
    applicable laws.</p>
        <br>
    <p>You may also designate an authorized agent, in writing or through a power of attorney,
    to request to exercise the above rights on your behalf.  If you should use an authorized
    agent to exercise your various rights under the CCPA, we may require that you provide
    your authorized agent with written permission to exercise your various rights and to
    verify your own identity with us. If your authorized agent does not submit proof that they
    have been authorized by you to submit verified requests for disclosure and deletion, we
    reserve the right to deny such a request that we have received and will explain to your
    authorized agent why we have denied such request. The authorized agent may submit
    a request to exercise these rights by emailing the letter of authorization or power of
    attorney to <a href="mailto:WebBankHR@webbank.com">WebBankHR@webbank.com</a>.</p>
    <br>
    <p>We currently do not collect household data. If we receive a request submitted by all
    members of a household, we will individually respond to each request. We will not be
    able to comply with any request by a member of a household under the age of 13, as
    we do not collect personal information from any person under the age of 13.</p>
    <br>
    <p>We will respond to your request within forty-five (45) days after receipt of a Verifiable
    Consumer Request for a period covering twelve (12) months and for no more than twice
    in a twelve-month period. We reserve the right to extend the response time by an
    additional forty-five (45) days when reasonably necessary and provided consumer
    notification of the extension is made within the first forty-five (45) days.</p>
    <br>
    <h4 class="pp-section-title">6. Contact Us</h4>
    <p>If you have any questions about this California Employee Privacy Policy or wish to
    exercise one of your privacy rights, please contact us using the following information:</p>
    <br>
            <p><strong>WebBank</strong></p>
            <table class="table is-fullwidth is-striped">
                <tbody>
                    <tr>
                        <td><strong>Email:</strong></td>
                        <td><a href="mailto:WebBankHR@webbank.com">WebBankHR@webbank.com</a></td>
                    </tr>
                    <tr>
                        <td><strong>Phone:</strong></td>
                        <td>1-884-994-BANK (2265)</td>
                    </tr>
                    <tr>
                        <td><strong>Mail:</strong></td>
                        <td>
                            <address>
                                215 S. State St.<br>
                                Suite 1000<br>
                                Salt Lake City, UT 84111
                            </address>
                        </td>
                    </tr>
                </tbody>
            </table>
    <br>
    </div>
</template>
