<template>
    <section class="is-hidden-desktop">
        <img src="https://assets.webbank.com/terms.png">
    </section>

    <section class="section">
        <div class="container">


            <div class="content">
                <h1 class="terms-page-title">TERMS OF USE</h1>
                <p>THE FOLLOWING TERMS AND CONDITIONS (“TERMS OF USE”) GOVERN THE USE OF THIS WEBSITE. PLEASE READ THEM
                    CAREFULLY BEFORE ACCESSING THE WEBSITE. BY ACCESSING THIS WEBSITE OR ANY PAGES HEREOF, YOU ACKNOWLEDGE
                    AND AGREE TO BE BOUND BY THESE TERMS OF USE. IF YOU DO NOT AGREE WITH THE TERMS OF USE, DO NOT ACCESS
                    THE WEBSITE OR ANY OF ITS PAGES.</p>
                <br>
                <p>These Terms of Use are provided on behalf of WebBank and its affiliates (“Bank,” “we,” “us” and “our”) to
                    you (“your,” “user”) . By using www.webbank.com, including any pages thereof (the “Website”), you acknowledge
                    that you have read, understand and agree to be bound by these Terms of Use, all disclosures, agreements,
                    statements, notices and other documents related to our savings accounts and/or time deposit account(s)
                    and the related services offered by the Bank, regardless of whether or not you are a Bank client or
                    registered user of our accounts or services (each, a “Service,” and collectively referred to as the “Services”).
                    If you do not wish to be bound by these Terms of Use, you should not access or use the Website or Services.
                    If there is any inconsistency between these Terms of Use and another agreement you enter into that is applicable
                    to Services offered on the Website, then the other agreement will take precedence as it applies to such Services.</p>
                <br>
                <p>In order to use the Site, you must be:</p>
                <ul>
                    <li>at least 18 years old, and</li>
                    <li>a legal resident of the United States.</li>
                </ul>
                <p>If you do not satisfy the above restrictions, please do not use the Website or access content from the Website.</p>
                <br>
                <p>Unauthorized use of the Website, including, but not limited to, unauthorized use of WebBank's Services, or misuse
                    of any information posted on the Website is strictly prohibited. WebBank makes no representation that the Services
                    described on the Website are available in all countries. Your eligibility for particular Services is subject to
                    final determination by the Bank.</p>
                <br>
                <h2 class="terms-section-title">CHANGES AND MODIFICATIONS</h2>
                <p>We reserve the right in our sole discretion for any reason to temporarily or permanently modify or discontinue the
                    Website or any portion of the Website or modify these Terms of Use at any time without notice to you. Changes to
                    these Terms of Use will become effective immediately upon the posting thereof, unless prohibited by applicable
                    laws or regulations. Please review these Terms of Use from time to time because your continued use of the Website
                    following the posting of changes will constitute your acceptance of the revised Terms of Use.</p>
                <br>
                <h2 class="terms-section-title">YOUR ACCOUNT; SECURITY</h2>
                <p>You will be required to create and maintain an account to access certain parts of the Website and use the Services.
                    When you create an account, you will be required to pick a username and a password. You may not disclose your username
                    or password to any third party. If you learn of any unauthorized use of your password or account, please contact us
                    immediately.You agree to (i) maintain the security of your username and password, (ii) maintain and promptly update
                    any information you provide to us in connection with your account and to keep it accurate, current and complete, and
                    (iii) be fully responsible for all use of your account and for any actions that take place using your account.</p>
                <br>
                <h2 class="terms-section-title">USE OF PERSONAL INFORMATION</h2>
                <p>WebBank's practices and policies with respect to the collection, use and sharing of nonpublic personal information
                    collected from or about you when you visit the Website is governed by the WebBank Online Privacy Notice, which is
                    hereby incorporated in its entirety and available at this website <router-link to="/privacy">here</router-link>.</p>
                <br>
                <h2 class="terms-section-title">RESTRICTIONS ON USE</h2>
                <p>You agree to abide by all applicable laws and regulations in your use of the Website and the Services. You agree that
                    you will not use any device, software or routine to interfere or attempt to interfere with the proper working of the
                    Website or any transaction being conducted on the Website. You agree that you will not register for an account on behalf
                    of an individual other than yourself or impersonate any other person or entity, or falsely state or otherwise misrepresent
                    your identity, age, or your affiliation with any person or entity. You further agree that you will not use or attempt to
                    use another individual's account without our authorization.</p>
                <br>
                <h2 class="terms-section-title">INTELLECTUAL PROPERTY</h2>
                <p>You agree that the Website and all logos and trademarks and service marks and/or trade dress, whether or not registered
                    (collectively, the “Marks”) that are related to our Services are the property of the Bank or of our licensors. You may
                    not copy, imitate or use the Marks without our prior written consent. In addition, the copyright in all page headers,
                    custom graphics, button icons and scripts, web pages and the information and material contained therein are owned by
                    the Bank unless otherwise indicated. Marks owned by third parties are the responsibility of their respective owners.
                    You may not copy, imitate or use any of them without our prior written consent.</p>
                <br>
                <h2 class="terms-section-title">NO WARRANTIES; DISCLAIMERS</h2>
                <p>Although reasonable efforts are made to ensure that the material appearing on this Website is correct and reasonably timely,
                    accuracy and timeliness are not guaranteed. We make no representations or warranties as to the accuracy and timeliness of
                    the material on the Website and assume no responsibility for the consequences of its use. Your use of this information
                    is at your own risk.</p>
                <br>
                <p>THIS WEBSITE, OUR SERVICES AND ALL OF THE CONTENT IS PROVIDED “AS IS” AND “AS AVAILABLE,” WITHOUT ANY WARRANTY, EITHER EXPRESS
                    OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, ANY WARRANTIES (I) AS TO TITLE, MERCHANTABILITY, FITNESS FOR ORDINARY PURPOSES,
                    FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, SYSTEM INTEGRATION, AND WORKMANLIKE EFFORT; (II) THE QUALITY, ACCURACY,
                    TIMELINESS OR COMPLETENESS OF THE WEBSITE OR SERVICES OR ANY ASPECT THEREOF; (III) THOSE ARISING THROUGH COURSE OF DEALING,
                    COURSE OF PERFORMANCE OR USAGE OF TRADE; (IV) THE WEBSITE OR SERVICES CONFORMING TO ANY FUNCTION, DEMONSTRATION OR PROMISE
                    BY BANK; AND (V) THAT ACCESS TO OR USE OF THE WEBSITE AND/OR SERVICES WILL BE UNINTERRUPTED, ERROR FREE OR COMPLETELY SECURE.
                    WE DO NOT WARRANT THAT THE WEBSITE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. THIS DOES NOT AFFECT THOSE WARRANTIES
                    WHICH ARE INCAPABLE OF EXCLUSION, RESTRICTION OR MODIFICATION UNDER THE LAWS APPLICABLE TO THESE TERMS OF USE.</p>
                <br>
                <h2 class="terms-section-title">LIMITATION ON LIABILITY</h2>
                <p>You agree that all access and use of the Website and its contents and your use of the Services is at your own risk. Neither Bank,
                    nor any third party involved in creating, producing or delivering the Website and/or Services, has or will have any
                    responsibility for any consequences relating, directly or indirectly, to any action or inaction that you may take based on
                    the Website and/or Services, or any aspect thereof.</p>
                <br>
                <p>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, NEITHER WE NOR OUR AFFILIATES NOR OUR OR THEIR RESPECTIVE OFFICERS, DIRECTORS,
                    EMPLOYEES, AGENTS, LICENSORS, REPRESENTATIVES, NOR ANY THIRD-PARTY PROVIDERS TO THE WEBSITE, ARE LIABLE FOR ANY DIRECT, INDIRECT,
                    PUNITIVE, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES OR OTHER INJURY ARISING OUT OF OR IN ANY WAY CONNECTED WITH USE OF THE
                    WEBSITE, OR WITH ANY DELAY OR INABILITY TO USE THE WEBSITE, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS AND SERVICES OBTAINED
                    THROUGH THE WEBSITE, WHETHER RESULTING IN WHOLE OR IN PART, FROM BREACH OF CONTRACT, TORTIOUS BEHAVIOR, FEDERAL OR STATE
                    SECURITIES LAWS, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF THE BANK OR REPRESENTATIVES THEREOF ARE ADVISED OF THE
                    POSSIBILITY OF SUCH LIABILITY, DAMAGES, LOSSES OR EXPENSES. NO ACTION ARISING OUT OF OR PERTAINING TO THESE TERMS OF USE MAY
                    BE BROUGHT MORE THAN ONE (1) YEAR AFTER THE CAUSE OF ACTION HAS ARISEN.</p>
                <br>
                <h2 class="terms-section-title">INDEMNIFICATION</h2>
                <p>You agree to indemnify, defend, and hold harmless Bank, its affiliates, partners, officers, employees, agents, representatives,
                    suppliers and content and service providers from and against all losses, expenses, damages and costs, including reasonable
                    attorneys' fees, resulting from any violation of these Terms of Use or any activity, including negligent or wrongful conduct,
                    by you in connection with your use of this Website or the Services. You will cooperate as fully as reasonably required in the
                    defense of any such claim or demand. We and any third party involved in creating, producing or delivering the Website or
                    Services reserves the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by
                    you, at your expense, and you will not in any event settle any such matter without the prior written consent of Bank and any
                    such third party.</p>
                <br>
                <h2 class="terms-section-title">ACCEPTABLE USE</h2>
                <p>You accept sole responsibility for all of your activities using the Website. You may not use the Website in a manner that:</p>
                <ul>
                    <li>Uses technology or other means not authorized by us to access the information on the Website or our systems;</li>
                    <li>Uses or launches any manual or automated device or system, including "robots," "spiders," or "offline readers," to (a)
                    retrieve, index, “scrape,” “data mine,” access or otherwise gather any information on the Website or our systems, (b)
                    reproduces or circumvents the navigational structure or presentation of the Site or (c) otherwise harvests or collects
                    information about users of the Site;</li>
                    <li>Reverse engineers, decompiles or disassembles any portion of the Website, except where such restriction is expressly
                    permitted by applicable law;</li>
                    <li>Attempts to introduce viruses or any other computer code, files, or programs that interrupts, destroys, or limits the
                    functionality of any computer software, hardware, or telecommunications equipment;</li>
                    <li>Attempts to gain unauthorized access to our computer network or user accounts;</li>
                    <li>Encourages conduct thatwould constitute a criminal offense or that gives rise to civil liability;</li>
                    <li>Harasses, abuses, stalks, threatens, defames, or otherwise infringes or violates the rights of us or any other party
                    (including rights of publicity or other proprietary rights);</li>
                    <li>Is unlawful, fraudulent, or deceptive;</li>
                    <li>Attempts to damage, disable, overburden, or impair our servers or networks;</li>
                    <li>Reproduces, modifies, adapts, translates, creates derivative works of, sells, rents, leases, loans, timeshare, distributes
                    or otherwise exploits any portion of (or any use of) the Site except as expressly authorized herein, without our express
                    prior written consent;</li>
                    <li>Fails to comply with applicable third-party terms; or</li>
                    <li>Otherwise violates these Terms.</li>
                </ul>
                <br>
                <h2 class="terms-section-title">TERMINATION</h2>
                <p>Subject to applicable law and the terms of any Services, we reserve the right, in our reasonable discretion, to terminate your
                    license, your use of the Website, your user account or any Service provided to you and to assert our legal rights with respect
                    to content or use of the Website that we reasonably believe is, or might be, in violation of these terms or the terms of any
                    Service.</p>
                <br>
                <h2 class="terms-section-title">SUBMISSIONS</h2>
                <p>All information submitted to WebBank through this Website shall be deemed to be and will remain the property of WebBank,
                    including any ideas, concepts, know-hows, processes and/or techniques contained in any information that you may provide
                    to us through this site. WebBank shall be free to use any information submitted through the Website for any purpose without
                    any obligations of confidentiality, except as agreed upon by us through a direct customer relationship with you, as specifically
                    agreed by us in writing, or as otherwise required by applicable law or regulation.</p>
                <br>
                <h2 class="terms-section-title">HYPERLINKS</h2>
                <p>Your agree that the use of any hyperlinks on this Website to facilitate transfers to other web sites operated by third parties
                    is at your own risk.  WebBank is not responsible for the content contained in or the accuracy of third party web sites which
                    are available through a hyperlink from this Website.  We neither endorse nor guarantee the products and/or services that may
                    be offered on any third party web sites.  You should review the privacy policy and/or policy statements of any third party web
                    site before you provide any personal or confidential information at such a web site.</p>
                <br>
                <h2 class="terms-section-title">JURISDICTION</h2>
                <p>These Terms of Use shall be governed by and construed in accordance with the laws of the State of Utah, without giving effect
                    to any principles of conflicts of law. The Website is controlled and operated by WebBank from its offices within the State of
                    Utah, United States of America. The Bank makes no representation that the Website or the Services are appropriate or available
                    for use in other locations. The information provided on this Website is not intended for distribution to, or use by, any person
                    or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would
                    subject the Bank, its affiliates, employees, directors, officers or agents to any registration requirement within such jurisdiction
                    or country.</p>
                <br>
                <h2 class="terms-section-title">CONTACT US</h2>
                <p>If you have questions or concerns regarding the Website or these Terms of Use, please contact us: WebBank, 215 South State Street,
                    Suite 1000, Salt Lake City, Utah 84111 or by calling 1-844-994-BANK (2265).</p>

            </div>

        </div>
    </section>

</template>

<script>
export default {

    created() {
        document.title = "Terms of Use - WebBank";
    },

}
</script>

<style scoped>
.terms-hero {
    background: rgba(31, 55, 107, 0.5) url(https://assets.webbank.com/terms.png);
    box-shadow: inset 0 0 0 1000px rgba(255, 255, 255, .4);
    background-position: center;
    background-size: cover;
    min-height: 400px;
}
.terms-page-title {
    font-size: 2.5rem;
    font-weight: bold;
    border-bottom: 1px solid black;
    margin-bottom: 25px;
    padding-bottom: 10px;
}
.terms-section-title {
    font-size: 1.5rem;
    font-weight: bold;
}
</style>
