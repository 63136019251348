<template>
    <section class="is-hidden-desktop">
        <img src="https://assets.webbank.com/terms.png">
    </section>

    <section class="section">
        <div class="container">
            <div class="content">
              <div class="columns">
                <div class="column is-8 is-offset-2">

    <h1 class="terms-page-title">WebBank Terms and Conditions for your Account</h1>
    <br>
    <h2 class="section-title">I. OVERVIEW</h2>
    <p><strong>1. Terms and Conditions</strong></p>
<p>This Terms and Conditions document, also known as your Deposit Account Agreement (“Agreement”), contains the disclosures governing your Online Savings Account(s) (each, a “Savings Account”) and/or Time Deposit Account(s) (each, a “TD Account”) with WebBank. A Savings Account and TD Account are referred to herein, collectively, as an “Account”). This document may be amended from time to time, at the sole discretion of WebBank. This Agreement contains important disclosures and information related to your Account. By opening, maintaining, and using your Account or any related services, you agree to the terms and conditions of this Agreement. Please read this Agreement carefully.</p>
<p><strong>Definitions:</strong> When we say the “Bank” in this Agreement, we mean WebBank. “We,” “us,” or “our” also means the Bank. The words “you” or “your” mean each person who owns an Account with us. The term “customer” means you or any other person with an Account at the Bank. When we say “day” we are referring to a calendar day. Our “Business Days” are Monday through Friday, excluding standard holidays observed by the Federal Reserve System.</p>
    <p><strong>2. Contacts</strong></p>
    <p>You may reach us using the contact information below.</p>
    <p><strong>Customer Support:</strong> 1-844-994-BANK (2265)—Business Days, 8:00 AM to 5:00 PM Mountain Time (“MT”)</p>
    <div class="table-container">
      <table class="table is-fullwidth">
        <tbody>
          <tr>
            <td>Via U.S. Mail:</td>
            <td>WebBank<br>
                Attn: Deposit Operations<br>
                215 South State Street,<br>
                Suite 1000<br>
                Salt Lake City, UT 84111</td>
          </tr>
          <tr>
            <td>Via Email:</td>
            <td>customercare@webbank.com</td>
          </tr>
        </tbody>
      </table>
    </div>
<h2 class="section-title">II. ACCOUNT OPENING</h2>
<p><strong>1. Web Account Opening</strong></p>
<p>All Accounts must be opened through our website. We do not offer account opening by telephone or U.S. Mail.</p>
<p><strong>2. Opening and Maintaining an Account</strong></p>
<p>To open an Account, you must be least 18 years old, have a Social Security Number or Individual Taxpayer Identification Number and have a physical street address in the United States of America. Alternatively, your Account application will be considered on a case by case basis if you have a physical street address in the U.S. territories of Puerto Rico, United States Virgin Islands or American Samoa (“Specified U.S. Territories”); or a military or embassy address on a U.S. military base or at a U.S. embassy (i.e., an Army/Air Post Office, Fleet Post Office, or Diplomatic Post Office address) (“U.S. Jurisdictions”). You may not use a post office box (other than on a U.S. military base or at a Diplomatic Post Office) as an address to open an Account. Accounts may only be opened by natural persons and may only be used for personal, family, or household purposes. Accounts may not be opened or maintained by businesses or used for business purposes.</p>
<p>Accounts may not be opened in the name of a trust or other legal entity or by a fiduciary (e.g., executor, conservator, guardians, trustees, etc.). If, however, a fiduciary is appointed by a court or by operation of law after Account opening, we may allow the fiduciary to access and control an Account on a temporary basis; thereafter, the Account will be closed.</p>
<p><strong>3. Important Information About Opening an Account</strong></p>
<p>Federal law requires all financial institutions to obtain, verify, and record information that identifies each Account owner. This means that when you apply to open an Account (as an individual or joint owner) or when you are added as a joint owner to an existing Account, we will ask for your name, address, date of birth, Social Security Number or Individual Taxpayer Identification Number, and other information that will allow us to identify you. We may also ask for a copy of your driver’s license or other identifying documents. You authorize us and our agents and service providers to use the information you provide at the time of Account opening to verify your identity or confirm information about you.</p>
<p><strong>4. Tax Reporting and Backup Withholding</strong></p>
<p>Federal law requires us to have a valid Social Security Number or Individual Taxpayer Identification Number for each Account so that we can correctly report the interest you have earned to the relevant tax authorities. In the case of a joint Account, interest earned will be reported under the Social Security Number or Individual Taxpayer Identification Number for the Primary Owner (as defined below). Accordingly, you must certify your Social Security Number or Individual Taxpayer Identification Number during the online application process. If you do not provide us with and certify a valid Social Security Number or Individual Taxpayer Identification Number within the time period specified, we are required to withhold a percentage of the taxable interest that we pay to you. This withholding is commonly referred to as “Backup Withholding.” In some states, we may also be required to withhold taxable interest for state taxes.</p>
<p><strong>5. Accounts Types</strong></p>
<p>We offer Savings Accounts and Time Deposit Accounts.</p>
  <ol type="a">
    <li>Savings Account—This Account allows you to make deposits and withdrawals by Automated Clearing House (ACH) transfer, wire transfer, or transfer from another Account at the Bank. Limits apply to certain types of transfers from your Account. Online Savings Accounts are subject to various other provisions in this Agreement, including section “III. Online Savings Accounts; Truth in Savings Disclosure.”</li>
    <li>Time Deposit Account—This Account allows you to make a one-time deposit of principal by ACH Transfer, wire transfer, or transfer from another Account at the Bank at Account opening or during the renewal grace period. A TD Account may be opened for various specified time periods, or “terms,” which end on a maturity date. Early withdrawals of principal prior to the maturity date are restricted. TD Accounts are subject to various other provisions in this Agreement, including section “IV. Time Deposit Accounts; Truth in Savings Disclosure.”</li>
  </ol>
<p>We will <strong>not</strong> provide you with any evidence of TD Account ownership, such as a passbook or certificate. Your periodic statement will serve as evidence of your TD Account with us.</p>
<p><strong>6. Account Ownership</strong></p>
<p>All Accounts can be held in the types of ownership described below.</p>
<ol type="a">
  <li>Individual Accounts—An individual Account is owned by one natural person and only the individual Account owner has a present right to funds in the Account.</li>
  <li>Joint Accounts—A joint Account is owned by and in the names of two natural persons (with a limit of two (2) owners per Account). Each Account owner has a right to the funds in a joint Account. Each Account owner can withdraw any or all available funds at any time or close the Account. We may use the funds in a joint Account to cover any: (i) rights of set-off, levy, attachment, garnishment or other valid legal process or court order, relating to the interest of any one or more of the Account owners; or (ii) requests from a trustee in bankruptcy, receiver in any state or federal insolvency proceeding, or the duly authorized insolvency representative of any one or more of the Account owners. We are not required to determine net contributions from each Account owner. The Bank shall not be liable to any joint Account owner for continuing to credit deposits or honor withdrawal requests from any joint Account owner.
    <ol>
      <li>All joint Accounts are owned by the joint owners as joint tenants with right of survivorship. A right of survivorship means that if one of the joint owners passes away, the money in the Account belongs entirely to the surviving joint owner. For example, if there are two joint owners and one dies, the balance in the Account will be owned by the surviving joint tenant.</li>
      <li>If we provide notice to one joint owner, all joint owners are deemed to have received notice, except as prohibited by law. At the time of Account opening, the joint owner entered first will serve as the “Primary Owner.” The Primary Owner will receive tax documents, including 1099s. All joint owners may have online access to the joint Account on our website. Certain Account preferences may be determined by the Primary Owner for all joint owners. We make no representation as to whether your joint Account will meet the specifications for a joint account under the laws of any state other than Utah. If you would like your joint Account to be treated as a joint account under any particular state law, you are solely responsible for determining that your joint Account meets your applicable requirements.</li>
    </ol>
  </li>
  <li>Payable-On-Death (“POD”) Designation—A POD designation may be added to an individual Account or a joint Account. A POD designation allows you to designate one or more beneficiaries (with a limit of six (6)) who will receive the funds in your Account upon your death. If more than one beneficiary is designated, each beneficiary will be paid in equal shares. You may only designate a natural person or trust (with appropriate documentation) as a beneficiary for your Account. We reserve the right to remove a POD designation that does not meet our requirements. If you have designated one or more beneficiaries for your Account, that designation cannot be changed by a provision in your Will. If you add a POD designation to your Account, the Account belongs to you and any joint owners during the lifetime of the Account owner(s) and the funds in the Account belong to the designated POD beneficiaries upon the death of all Account owners. In the case of a deceased beneficiary, the funds in the Account will be paid in equal shares to the beneficiaries who have survived. The law of the state in which you reside may prescribe requirements and may restrict these types of accounts and account designations. We make no representation as to whether an Account with a POD designation will meet the specifications for a POD account under the laws of any state other than Utah. If you would like your Account to be treated as a POD account under any particular state law, you are solely responsible for determining that your Account meets your applicable requirements.</li>
</ol>

<h2 class="section-title">III. ONLINE SAVINGS ACCOUNTS; TRUTH IN SAVINGS DISCLOSURE</h2>
<p><strong>1. Minimum Opening Balance</strong></p>
<p>The minimum deposit required to open and maintain a Savings Account is $1,000.00. If your account balance falls below this minimum amount, your Savings Account will be closed and the balance of the funds remaining in the Account will be returned to you in a manner deemed appropriate by us. Funding must be initiated using electronic funds transfer, also known as ACH, at the time of account application.</p>
<p><strong>2. Maximum Deposit Limitation</strong></p>
<p>The Bank reserves the right to establish a maximum aggregate deposit balance for its customers or for any individual customer, in its sole discretion. The maximum initial funding limit per account is $500,000.</p>
<p><strong>3. Interest</strong></p>
<p>Interest is compounded daily and credited to your Account monthly. Interest is calculated using the daily balance method. This method applies a daily periodic rate to the principal and interest that has accrued in the Account each day. This means that the interest from your Account is calculated every day on a 365-day year/ 366-day for leap years. Interest begins to accrue on the Business Day that we receive your deposit. If the Account is closed before interest is credited, accrued interest will be paid through the last full day before the day the Account is closed. We only pay interest in whole cents and use standard rounding rules to calculate the amount of interest earned. This means that an amount equal to or greater than one half of one cent accrued during a monthly interest period is rounded up to the next whole cent and an amount less than one half of one cent accrued during a monthly interest period is rounded down to the next lower whole cent. Our Savings Account is a variable rate deposit and we may, at our discretion, at any time, change the interest rate and the Annual Percentage Yield (“APY”). The APY at which a Savings Account would earn interest each year if all interest paid on the Savings Account remains in the Account, can be found on our website.</p>
<p><strong>4. Transaction Limitations</strong></p>
<p>You are only permitted to make six (6) withdrawals or transfers per monthly statement period from your Savings Account. This limit does not apply to the number of deposits you make. This limit does apply to the following types of withdrawals or transfers:</p>
<ul>
  <li>Preauthorized or automatic withdrawals or transfers initiated from your Savings Account, whether through us or an external bank;</li>
  <li>Withdrawals or transfers requested by calling Customer Support (ACH Transfers, internal transfers between Accounts at the Bank, and wire transfers); and</li>
  <li>Withdrawals or transfers requested on our website (ACH Transfers and internal transfers between Accounts at the Bank).</li>
  <li>If you make more than six (6) withdrawals or transfers in a monthly statement period, we may, in our discretion, deny and/or reject the excess transfer(s), and we may close your Savings Account.</li>
</ul>

<p><strong>5. Notice of Withdrawal Requirements for Savings Accounts</strong></p>
<p>Pursuant to federal law, the Bank reserves the right to require you to give us written notice of your intention to make a withdrawal seven (7) days before the withdrawal is made. By permitting you to make a withdrawal without requiring seven (7) days’ prior notice, we are not waiving our right to require the notice.</p>

<h2 class="section-title">IV. TIME DEPOSIT ACCOUNTS; TRUTH IN SAVINGS DISCLOSURE</h2>
<p><strong>1. Minimum Deposit</strong></p>
<p>The minimum deposit required to open and maintain a TD Account is $2,500.00. If your account balance falls below this minimum amount, your TD Account will be closed and the balance of the funds remaining in the Account will be returned to you in a manner deemed appropriate by us. Funding must be initiated using electronic funds transfer, also known as ACH, at the time of account application.</p>
<p><strong>2. Maximum Deposit Limitation</strong></p>
<p>The Bank reserves the right to establish a maximum aggregate deposit balance for its customers or for any individual customer, in its sole discretion. The maximum initial funding limit per account is $500,000.</p>
<p><strong>3. Interest</strong></p>
<p>The interest rate and APY is fixed for the term of your TD Account. The stated APY assumes interest remains on deposit until maturity, subject to any applicable maximum deposit limits. If you elect to withdraw interest during the term of the TD Account, the APY you receive will be lower than the stated APY. Interest is compounded daily and credited to the TD Account monthly. Interest is calculated using the daily balance method. This method applies a daily periodic rate to the principal and interest that has accrued in the TD Account each day. This means that the interest from your Account is calculated every day on a 365-day year/ 366-day for leap years. Interest begins to accrue on the Business Day that we receive the TD Account funding deposit. If the TD Account is closed before interest is credited, accrued interest will be paid through the last full day before the day the TD Account is closed. An early withdrawal penalty will apply if closed prior to the maturity date.</p>
<p><strong>4. Payment of Interest</strong></p>
<p>Accrued interest will be credited to your TD Account monthly. We only pay interest in whole cents and use standard rounding rules to calculate the amount of interest earned. This means that an amount equal to or greater than one half of one cent accrued during a monthly interest period is rounded up to the next whole cent and an amount less than one half of one cent accrued during a monthly interest period is rounded down to the next lower whole cent. You can withdraw the interest credited to your Account (without an early withdrawal penalty) at any time during the term of the TD Account. For example, you may initiate a withdrawal on a onetime basis or each month to transfer credited interest to the linked external account that was originally used to fund your TD Account. Transfers of interest to your other Accounts at the Bank are subject to the any maximum deposit limitations. If you elect to withdraw interest during the term of the TD Account, the APY you receive will be lower than the stated APY.</p>
<p><strong>5. Early Withdrawal of Principal</strong></p>
<p>WebBank does not permit partial withdrawals of your principal balance in your TD Account at any time prior to the maturity date. You may, at the sole discretion of WebBank, withdraw the entire principal balance prior to the maturity date, but you will be charged an early withdrawal penalty. The entire principal balance, however, may be withdrawn prior to maturity without a penalty in the case of your death or if there is a judicial or administrative determination that you are legally incompetent. The Bank will require documentation that it deems satisfactory supporting the claims of death or incompetence.</p>
<p><strong>6. Early Withdrawal Penalty</strong></p>
<p>You are not permitted to withdraw a portion of your principal at any time prior to maturity. If you withdraw the entire principal amount from your TD Account prior to maturity, you will be charged an early withdrawal penalty based on the term of your TD. The applicable early withdrawal penalties are as follows:</p>
<div class="table-container">
  <table class="table is-fullwidth">
    <thead>
      <tr>
        <th>TD Term</th>
        <th>Early Withdrawal Penalty</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>Less than 1 year</td>
        <td>3 Months simple interest</td>
      </tr>
      <tr>
        <td>1 year to 2 years</td>
        <td>6 Months simple interest</td>
      </tr>
      <tr>
        <td>3 years</td>
        <td>9 Months simple interest</td>
      </tr>
      <tr>
        <td>5 years</td>
        <td>1 Year simple interest</td>
      </tr>
    </tbody>
  </table>
</div>
<p>For purpose of calculating the early withdrawal penalty, simple interest is calculated on a 365-day calendar year, even in a leap year. An early withdrawal penalty could result in the reduction of the principal.</p>
<p><strong>7. Maturity Grace Period; Renewal</strong></p>
<p>There is a ten (10) day grace period (the “Grace Period”) beginning on the day after the maturity date (maturity date plus ten (10) days), in which you may withdraw all or a portion of your principal without penalty or renew your TD Account with the same or a different term. You may also add additional funds, up to any aggregate deposit maximum, at this time. If you withdraw the funds in your TD Account during the Grace Period, interest will continue to accrue at the same interest rate and APY as the prior term and will be paid through the last full day before the day the funds in the TD Account are withdrawn We will send you a notice of the pending renewal of your TD Account. Please call by the end of the Grace Period if you would not like to renew your TD Account. If we do not hear from you by the end of the Grace Period, your TD Account will automatically renew for another term. The renewal term will begin (retroactively) on the day after the maturity date of the previous TD Account term. The principal balance for the renewal term will be the total balance in the TD Account (principal plus the interest that was not previously withdrawn) on the date the TD Account is renewed. Each renewal will be on the same terms, except that the rate of interest will be the highest interest rate and APY we offer for the same TD term that is available during the Grace Period. If such TD Account term is no longer available, the renewal term and rate of interest will be for the next shortest TD Account term that we offer at that time. We reserve the right not to renew your TD Account if we send written notice of non-renewal to your address of record with the Bank at least thirty (30) days before the date your TD Account will mature. If we send you a notice of non-renewal and you fail to contact us by the end of the Grace Period or we are unable to reach you to return your funds, you agree that we may transfer the balance from your TD Account to a Savings Account.</p>

<h2 class="section-title">V. DEPOSITS AND WITHDRAWALS</h2>
<p><strong>1. Deposits and Withdrawals</strong></p>
<p>You may make deposits into and withdrawals from your Savings Account as described below in this subsection, subject to the other limitations specified in this Agreement. We do not accept any deposits denominated in any foreign currency.</p>
<p>If we receive a deposit before 3:00 PM MT on a Business Day, we will consider that Business Day to be the day of your deposit. However, if we receive a deposit after 3:00 PM MT or on a non-Business Day, we will consider the deposit as having been made the next Business Day. See section “VIII. Funds Availability” for information on when deposited funds will be available.</p>
<p>Requests for withdrawals: (1) to be made by a wire transfer and ; (2) to be made by telephone for (i) an ACH Transfer; or (ii) and an internal transfer between Accounts held at the Bank, which are received by us before 1:00 PM MT on a Business Day, shall be considered received on that Business Day. Any withdrawal such requests that are received by telephone after 1:00 PM MT on a Business Day or on a non-Business Day shall be considered received on the next Business Day.</p>
<ol type="a">
  <li><strong>ACH Transfer Initiated by Us with a Linked External Account</strong> — You may deposit funds into or withdraw funds from your Savings Account at the Bank by going to our website (subject to certain transfer limits) or calling Customer Support to initiate an ACH Transfer into or from a linked account at an external bank. We do not accept requests made through email, secure message, or mail to initiate ACH Transfers. For us to initiate an ACH Transfer to or from your Account at the Bank to or from your external account, the external account must be registered (linked) with us. To learn more about linked external accounts, see subsection “V.2. Registering (Linking) an External Account to Your Account at the Bank.”</li>
  <li><strong>ACH Transfer Initiated by an External Bank or Through a Third Party</strong> — You may deposit funds into or withdraw funds from your Savings Account by having an external bank (directly or through a third party) initiate an ACH Transfer into or from your Account at the Bank. The external account that you are depositing funds into or withdrawing funds from must be at an external bank located in the U.S., Specified U.S. Territories, or U.S. Jurisdictions.</li>
  <li><strong>Wire Transfer</strong> — You may deposit funds into your Savings Account at the Bank from an account you own at an external bank by having the external bank send a wire transfer to the Bank. You may withdraw funds from your Account by calling Customer Support to initiate a wire transfer from your Account at the Bank to an account you own at an external bank. A wire transfer initiated from your Account at the Bank may take one (1) to three (3) Business Days to process. We do not accept requests made through email, secure message, or mail to initiate wire transfers. The account at the external bank that we are sending wire transfers to must be owned by you and must be an account at a bank located in the U.S., Specified U.S. Territories, or U.S. Jurisdictions.</li>
  <li><strong>Internal Transfer Between Accounts at the Bank</strong> — You may transfer funds between Accounts at the Bank by going to our website or calling Customer Support to initiate an internal transfer. We do not accept requests made through email, secure message, or mail to initiate internal transfers.</li>
  <li><strong>Check Deposits into Your Account; Bank Checks</strong> — WebBank does not accept checks for deposit to any Account. All deposits must be by ACH Transfer or wire transfer. WebBank does not offer withdrawals via check. In certain situations, such as if you close your Account, if we close your Account, or if you exceed an applicable maximum deposit limit, we may issue bank check payable to you. If a bank check issued to you is lost, stolen, or destroyed, call Customer Support immediately. If a bank check issued to you is lost, stolen, or destroyed, we may not reissue you a check or provide you with replacement funds within ninety (90) days of the issuance date of the original check. Prior to reissuing a bank check or providing you with replacement funds, we may require that you sign an affidavit attesting that the check was lost, stolen, or destroyed and an indemnity where you agree to reimburse us for certain losses related to such check and to obtain a bond in twice the amount of such check.</li>
  <li><strong>Cash</strong> — We do not accept any deposits in cash, whether in U.S. dollars or any foreign currency. All deposits are considered to be non-cash deposits. If you send any cash to us, it will be returned to you and you agree that you accept any and all risk of loss from the transmission of such currency.</li>
</ol>
<p><strong>2. Linking an External Account to Your Account at the Bank</strong></p>
<p>You may initiate an ACH Transfer to transfer funds between your Account at the Bank and an account you own at an external bank if the external bank account has been registered (linked) at the Bank. Once your external account is linked, you may go to our website or call Customer Support to initiate an ACH Transfer to or from the linked external account.</p>
<ol type="a">
  <li><strong>Requirements to Link</strong> — To link an external account to your Account at the Bank, you must provide true and accurate information regarding the external account and the external account must: (i) be a deposit account held at a depository institution located in the U.S., Specified U.S. Territories, or U.S. Jurisdictions; (ii) have your same name in the title of the external account (with reasonable minor variations) as your Account at the Bank; (iii) be an account in which you have full right and authority to all the funds; and (iv) be individually or jointly owned by you. You may not link an external business account to your Account at the Bank. You may link any external account to your Account to fund such Account at account opening. Once open, you may link to your Account(s) you hold at the Bank only one (1) external account(s) which was used for funding an Account at the Bank</li>
  <li><strong>How to Link</strong> — You may link an external account to your Account at the Bank on our website. You authorize and agree to cooperate with us and our agents and service providers to verify that you own the external account and verify other information about your external account.</li>
</ol>
<p><strong>3. Deposit and Withdrawal Requirements:</strong></p>
<ol type="a">
  <li><strong>General Deposit Provisions</strong> — Subject to special rules for ACH Transfers (see subsection “V.3.b. ACH Transfer Provisions” below), when you make a deposit, we will act only as your collection agent and will not be responsible beyond the exercise of good faith and ordinary care. All deposits are provisionally credited subject to our receipt of final payment. If you transfer funds into your Account and the transfer is returned to us by the paying financial institution for any reason, you agree that we may deduct the amount of the transfer against your Account, without prior written notice to you. We may send the transfer back for collection a second time without notifying you, and you waive any notice of dishonor and protest. At our discretion, we may return, reject, and/or refuse to accept a deposit; for example, we may refuse to accept a transfer to your Account if we believe that accepting the transfer would cause us to violate any applicable law or cause your Account to exceed any applicable maximum deposit limits.</li>
  <li><strong>ACH Transfer Provisions</strong> —
    <ul>
      <li>You acknowledge that when we initiate ACH Transfers on your behalf, or receive ACH Transfers on your behalf, they will be processed under the National Automated Clearing House Association (“NACHA”) Operating Rules. ACH Transfers that are deposited into your Account are provisional and will not be credited to your Account until final payment is received. We are not required to give next-day notice to you of our receipt of an ACH Transfer. You agree that we may reverse any ACH Transfer of funds to or from your Account without prior notice to you at any time if permitted by NACHA Operating Rules, which includes but is not limited to the following reasons: (a) we credited your Account in an incorrect amount, (b) the transfer represents a duplicate credit to your Account, (c) you were not entitled to the deposit, or (d) you were not the intended recipient of the deposit. We may reverse any provisional credit to your Account for which final payment is not received.</li>
      <li>Recurring ACH Transfers that you have initiated from an Account at the Bank and that are returned by an external bank because, for example, you have closed your external account, or you provided an incorrect account number, may be deleted prior to the next scheduled ACH Transfer date. We have no obligation to contact you if we delete any such future ACH Transfers.</li>
    </ul>
  </li>
  <li><strong>Insufficient Funds</strong> — We will not permit withdrawals from your Account if there are not sufficient funds available in your Account for the amount of the ACH Transfer and the applicable fee and we will not be liable for dishonoring a transaction if you do not have sufficient funds to cover it. You must maintain sufficient available funds in your Account at all times to cover withdrawals or any other transactions negotiated or accepted by us on your behalf. We determine from time to time during each Business Day whether or not your Account contains sufficient available funds to pay a transaction (for example, ACH Transfer, internal transfers between Accounts at the Bank, wire transfer, or any other electronic transaction). In some circumstances, your Account might still become overdrawn. If this happens, you are responsible for the full amount of any overdrawn amount. You also agree to reimburse us for any losses we incur in collecting any overdrawn balances.</li>
  <li><strong>Restrictions on Withdrawal Methods</strong> — You are not permitted to link your Account with a credit, debit, gift, or other type of card issued by a third party or another bank. You are not permitted to make withdrawals from your Account by creating a check drawn on your Account. If you attempt to make any transactions with your Account using a credit, debit, gift or other type of card issued by a third party or another bank or by creating a check drawn on your Account, we will reject and/or return the transaction without notice to you and may consider such action to be a violation by you of this Agreement.</li>
  <li><strong>Authorization and Authentication for Transfers, Requests, and Account Access</strong> —
    <ul>
      <li>We will not permit transfers to or from your Account unless you provide us with satisfactory identification and any other documentation or information that we may require from time to time, as may be further specified in various sections of this Agreement.</li>
      <li>We may make ACH Transfers, wire transfers, or internal transfers between Accounts at the Bank based on instructions you give to us by calling Customer Support or through our website, unless prohibited by applicable law. Unless prohibited by applicable law, you agree to hold us harmless and indemnify us for any liability we may incur for reasonably acting upon such instructions that you provide to us.</li>
      <li>You agree that transfers conducted or requested by you, and Account access obtained through telephone or our website, may only be authorized and/or initiated upon satisfactory authentication of your identity through the use of a password, secret word, electronic signature or other such identifying means (each a “Credential”). Different types of transfers, requests, or access may require a different Credential and/or use of an additional security procedure. You accept sole responsibility for maintaining security over any Credential and any device using the Credential.</li>
      <li>You agree that any instructions received by us (such as requests to withdraw funds from your Account) that include any of your Credentials will be deemed to be authorized by you. The use of any of your Credentials by another person will be as effective as your use of the Credentials, regardless of whether the person affixing the Credential was authorized by you and regardless of the means by which the Credential was affixed. For example, if you provide your e-mail address and password to another person and that person uses that information to withdraw funds from your Account, you agree that we will deem the withdrawal to be authorized by you. Unless prohibited by applicable law, you agree to hold us harmless and indemnify us for any liability incurred for reasonably acting upon such instructions which bear any of your Credentials. You agree to keep confidential and to take all reasonable precautions and make all reasonable efforts to protect the secrecy of all Credentials issued to you, selected by you, or utilized by you. If any of your Credentials become lost or known to another person, you agree to notify us immediately so that a replacement may be issued.</li>
    </ul>
  </li>
  <li><strong>Reliance Upon Information Provided</strong> —
    <ul>
      <li>You acknowledge and agree that we are relying upon the information you provide, as well as information provided by the external bank sending transfers to you, when we process transfers to you or on your behalf.</li>
      <li>When we receive wire transfers or ACH Transfer requests to deposit funds into an Account, we rely on the Account number and you agree that we do not have a duty to determine whether the Account number provided to us matches the name or other information given to us with the transfer or transfer request. For this reason, we will not be liable to you if we credit a transfer using the Account number provided, even if such a transfer that was intended for your Account is credited to another customer’s Account.</li>
      <li>When you provide us with information to initiate an ACH Transfer or wire transfer, you agree that we will not be responsible for accuracy of the information or for any errors or discrepancies in the account names and numbers or the ABA Routing Transit Number and name of the external bank holding your account, and you agree that we have no responsibility to determine accuracy or investigate any of these errors or discrepancies.</li>
    </ul>
  </li>
  <li>For the avoidance of doubt, specific types of transfers (e.g., ACH Transfers and wire transfers) are subject to additional provisions set forth in this Agreement that govern those particular types of transfers. In the event of any conflict between a provision in this Section V.3. and a provision of this Agreement governing a specific type of transfer, the provision governing the specific type of transfer will prevail.</li>
</ol>

<h2 class="section-title">VI. YOUR ACCOUNT</h2>
<p><strong>1. Deposit Insurance Coverage</strong></p>
<p>The Federal Deposit Insurance Corporation (“FDIC”) insures deposits according to the ownership category (i.e., individual or joint) in which the funds are insured. Deposits are insured by the FDIC up to the standard maximum deposit insurance amount per depositor, per FDIC-insured bank, and per ownership category. For purposes of determining how much insurance is applicable to your Accounts, you need to consider accounts other than Online Savings Accounts or TD Accounts that you also hold at the Bank. To determine how much insurance is applicable to your Accounts with us and for any other FDIC insurance requirements that may apply, please visit the FDIC’s website at www.fdic.gov/deposit/deposits or call the FDIC directly at 1-877- ASKFDIC (1-877-275-3342). You can also use the FDIC’s Electronic Deposit Insurance Estimator (EDIE) at www.fdic.gov/edie.</p>
<p>The FDIC only insures deposits held in insured banks and savings associations (collectively, “insured banks”) and only in the unlikely event of an insured bank’s failure. The FDIC does not insure assets issued by non-bank entities, such as crypto companies. The FDIC only pays deposit insurance after an insured bank fails. Coverage is only available for the deposits that are held in the insured bank at the time of its failure</p>
<p><strong>2. Designating a Power of Attorney</strong></p>
<p>You may give another person (called an “attorney in fact”) authority to act on your behalf by giving that person a power of attorney. To add an attorney in fact to your Account, you must provide us with power of attorney documentation that meets our requirements. Subject to applicable law, we may refuse to accept a power of attorney that does not meet our requirements or was not issued within the past two (2) years and require that you provide an updated power of attorney. Subject to applicable law, we may, in our sole discretion, refuse to honor any power of attorney or refuse to permit your attorney in fact to access and otherwise transact through your Account. An attorney in fact may not open an Account on your behalf. Once we accept your power of attorney, we may allow your attorney in fact to access and otherwise transact through your Account unless or until we receive and have had a reasonable opportunity to act on written notice that you have died or that the power of attorney has been revoked. We have no duty to monitor or ensure that the acts of your attorney in fact are for your use or benefit or are otherwise permissible under applicable law. We will not be liable if your attorney in fact exceeds his or her powers or does not comply with your instructions or applicable law. You agree to hold us harmless from and against any actions we take based upon the instructions of your attorney in fact or that your attorney in fact takes regarding your Account, unless or until we receive and have had a reasonable opportunity to act on written notice that you have died or that the power of attorney has been revoked.</p>
<p><strong>3. Death or Incompetence</strong></p>
<p>You agree to notify us immediately if any Account owner on your Account dies or is declared legally incompetent. In the case of an individual Account, if we have reason to believe the Account owner has died or has been declared legally incompetent, we may place a hold on the Account to retain funds and refuse to accept deposits or to permit withdrawals until we know and have verified the identity of your duly appointed representative. We will require proof of death or adjudication of incompetence (e.g., certified copy of court order, death certificate, or official record). Until we receive notice and any required proof of death or incompetence, we may act as if all owners are alive and competent. In the event we receive written notice from a personal representative, executor, administrator, conservator, or guardian purporting to represent you or your estate, we shall be entitled to rely on all information supplied and representations made in such written notice to the full extent permitted by applicable law. If certain payments originating from government entities are deposited into your Account after your death, we may be required to return those payments to the originator upon notice. If a withdrawal is made from an Account before a deposit subject to recall by a government or other entity is returned, you agree that your surviving joint owners or your duly appointed representative shall be liable for such recalled payment.</p>
<p><strong>4. Account Statements</strong></p>
<p>We will make Account statements for each of your Accounts available to you on our website (all Account statements are delivered electronically). The information in such Account statements will be sufficient to allow you to reasonably identify the items paid. You must examine the statement and notify us in writing of any error in the Account statement within sixty (60) days after the statement is mailed or made available to you. Your ability to recover any losses may be limited if you fail to notify us in accordance with applicable law. See section “IX. Electronic Fund Transfer Disclosure and Terms” for additional information on reporting and resolving errors involving Electronic Fund Transfers and section “X. Wire Transfer Terms” for additional information on reporting errors involving wire transfers. You should notify us promptly if you do not receive a notice that your Account statement is available online within a few days after the end of your statement cycle. For a joint Account, Account statements and notices made available to any one joint owner are deemed to be received by all joint Account owners, except as prohibited by law. You agree that any communication to you, including changes to the terms of this Agreement, may be sent on or with any monthly Account statement we send, consistent with applicable law. You may request a printed copy of your account statement by contacting our Customer Support department at 1-844-994-BANK (2265). A fee will be charged for each paper statement.</p>
<p><strong>5. Change of Address and Telephone Number</strong></p>
<p>You agree that we are entitled to rely upon the mailing address, e-mail address and telephone number you provide to us. You must promptly notify us of any change in your mailing address, your email address and/or your telephone number. We have no liability to you if you fail to notify us of a change in your mailing address, email address or telephone number. Notice of availability of Account statements and other important Account documents are sent to the last email address you have provided to us. Account statements and other important Account documents will be deemed to be provided to you when an email is issued alerting you that such materials are available online or are otherwise made available to you.</p>
<p><strong>6. Keeping Your Accounts Active</strong></p>
<p>Under applicable state abandoned property statutes, your Accounts will be considered inactive (dormant) and we may be required to send to the appropriate state the balances in your Account unless you have, within a specified period of time, contacted us or conducted activity related to your Account, including, for example:</p>
<ul>
  <li>Depositing or withdrawing funds (may exclude certain preauthorized and automatic deposits or withdrawals);</li>
  <li>Updating your address;</li>
  <li>Signing in to your Account on our website; or</li>
  <li>Writing or calling us concerning your Account.</li>
</ul>

<p>In general, a TD Account that has not reached initial maturity will not be considered inactive. Depending on applicable state law, if the TD Account renews automatically, it may still be considered inactive if you do not contact us or conduct activity related to it within a specified period of time after the initial maturity date. In general, the laws of the state of your last known address (as recorded in our records) will govern when your Account is considered dormant. Prior to remitting any balances in your Account to the appropriate state, we will attempt to contact you, as required by applicable law, using the contact information you have provided to us.</p>

<p><strong>7. Legal Action</strong></p>
<p>In the event we are served with levies, attachments, garnishments, summons, subpoenas, court orders or other documents evidencing legal action (“legal process”) that names any Account owner or related Account, we shall be entitled to rely upon the representations, warranties, and statements made in such legal process documents. You agree that we may respond to any such legal process in our own discretion without regard to jurisdiction. We will not contest on your behalf any legal action and may take action to comply with the legal process as we determine to be appropriate in the circumstances. If legal action is brought against you or your Account, we may refuse to permit or limit withdrawals from your Account until the legal action is satisfied or dismissed. Unless required by law, we may not send notice to you of our receipt of any of these notices of legal action. We do not send a notice if we believe the law prohibits us from doing so. Regardless of the terms of any levies, attachments, or garnishments, we have first claim to any funds in your Account. Any levy, attachment, or garnishment against your Account is subject to our right of set-off and security interest. If the law imposes conditions or limits on our ability to take or set off funds in your Accounts, to the extent that you may do so by contract, you waive those conditions and limits, and you authorize us to apply funds as we deem applicable. All Account owners on the Account agree jointly and severally to hold harmless and indemnify us for any losses, expenses, and costs, including attorneys’ fees, incurred as a result of complying with such legal process.</p>
<p><strong>8. Service Charges and Fees</strong></p>
<p>We charge service charges or fees for providing paper statements and notices, as well as outgoing wire transfers, as set forth in our Fee Schedule. We reserve the right to change any fee, to delete existing fees and to add new fees. You will receive notice of a change in fees in our discretion and as required by applicable laws and regulations. If you withdraw the principal balance from your TD Account prior to maturity, except in limited situations, you will be charged an early withdrawal penalty. External banks or third parties may charge you fees, for example, if you initiate a wire transfer from your external account to your Account at the Bank or if a transfer from your external account to your Account at the Bank causes your external account to become overdrawn. We are not responsible for fees imposed in connection with your external accounts.</p>
<p><strong>9. Closing or Restricting an Account</strong></p>
<p>We have the right to close or restrict any Savings Account or TD Account at any time without notice and without your consent for any reason unless prohibited by applicable law. The following is a representative list, but not a complete list, of reasons we may restrict and/or close your Account:</p>
<ul>
  <li>You provided incorrect or misleading information when opening your Account;</li>
  <li>You are maintaining an overdrawn Account;</li>
  <li>You are repeatedly exceeding the six (6) withdrawals or transfers per monthly statement period limitation for your Savings Account;</li>
  <li>You are conducting, or we suspect that you or a third party are conducting illegal or fraudulent activity;</li>
  <li>Your Savings Account balance falls below the minimum required balance;</li>
  <li>Your Savings Account is not funded by the end of the calendar month;</li>
  <li>You fail to fund your TD Account by the end of the calendar month;</li>
  <li>There is a dispute regarding your Account;</li>
  <li>We received a court order or other legal process prohibiting withdrawal; or</li>
  <li>You abuse our systems or violate this Agreement.</li>
</ul>
<p>If your Account has a balance when we close it, we will return the funds to you minus any applicable fee, penalty, unless prohibited by law, by ACH Transfer to the linked account from which your original funding was received. In limited circumstances, we may initiate a wire transfer (with your authorization) or mail a check to your current mailing address. We may refuse to pay any debits or other items presented or re-presented for payment after your Account is closed or restricted, but we are not obligated to refuse payment of those debits or other items. We will not be liable for the non-payment of any debit or other item presented after your Account is closed or restricted. Except as may be limited by applicable law, you agree to hold us harmless from and against any losses arising from, or in any way relating to, our refusal to pay or release funds in accordance with this section.</p>
<p><strong>10. Compliance with the Law</strong></p>
<p>You agree to comply with all applicable U.S. laws and regulations, including but not limited the economic and trade sanctions promulgated by the Office of Foreign Assets Control (“OFAC”) of the U.S. Treasury Department, and not to use your Account for illegal transactions or activities.</p>
<p><strong>11. Adjustments</strong></p>
<p>We may make adjustments to your Account to reflect corrections or changes to your balances, rates, fees and penalties (if any). For example, adjustment may occur if deposits or withdrawals are posted for the wrong amount, posting is delayed, or items are returned unpaid for any reason. In the event of an error or something else that has caused an overstated balance, you agree to reimburse us for the overstated amount.</p>
<p><strong>12. Information We Collect About You</strong></p>
<p>You authorize us to use information we have about you and share that information with our affiliates and third parties, except as provided by law or our Privacy Notice. For details about how we treat information we have about you, consult our Privacy Notice.</p>
<p><strong>13. Electronic Messages</strong></p>
<p>You acknowledge that data, including emails, may be accessed by unauthorized third parties when communicated between you and the Bank, using the Internet (for example, by such third party downloading spyware or malware onto your computer), telephone, or other electronic devices. We are not responsible for any misdirected data or disclosures that occur as a result of your use of third-party electronic communication channels.</p>
<p><strong>14. Calls and Messages to Your Mobile Device</strong></p>
<p>You agree that we or our agents or service providers may contact you regarding any matter for any non-telemarketing reason (e.g., processing service requests) using any kind of telecommunications technology at any email and telephone number you provide to us, including the phone number for your mobile device. You agree to receive these calls and messages, including pre- recorded or auto-dialed calls. You also agree that we may send text messages to any phone number for your mobile device you provide to us. You understand and accept that your telecommunications service provider may charge you for these calls and messages consistent with applicable law.</p>
<p><strong>15. Recording Telephone Calls and Electronic Communications</strong></p>
<p>For quality control purposes and for other reasons, you permit us to record and monitor your telephone conversations and electronic communications with us (including email). Unless the law requires otherwise, you consent in advance to such recording and monitoring and we do not need to remind you of these activities at the time of your call or other communication.</p>

<h2 class="section-title">VII. ADDITIONAL TERMS</h2>
<p><strong>1. Changes in Terms</strong></p>
<p>Accounts or services may change over time. We reserve the right to delay, discontinue, or make changes to Accounts or services, and to convert your existing Accounts and services into new types of Accounts and services. We may change the terms of this Agreement from time to time, and the revised agreements will supersede all prior versions. We will provide notice of changes, additions, or deletions as required by law. Unless we are required to provide you with advance notice, any such change will be binding on you when we mail you notice or make it available to you. If we are required to provide you with advance notice and you do not agree with a change, you may close your Account(s) before the effective date. Your use of your Account or our website after the effective date of a change constitutes the acknowledgement of your acceptance of such change.</p>
<p><strong>2. Security Interest; Right of Set-Off</strong></p>
<p>You grant us a security interest in your Account to secure payment of any money that you owe to us arising under this Agreement or any other agreements with the Bank. You acknowledge and grant us the right to use any of the funds in your Account to cover any debt you owe to us (referred to as a “right of set-off”). You agree that the security interests you have granted to us are consensual and in addition to any rights of set-off we possess. We may exercise our security interest or right of set-off without prior recourse to other sources of repayment or collateral, if any, and even if such action causes you to lose interest, incur any early withdrawal penalty, or suffer any other consequences from the exercise of our rights. If we exercise our security interest or right to set-off, we will notify you to the extent required by applicable law. We will not assert, claim, or exercise any right of set-off against any Account receiving a direct deposit of social security or supplemental security income and/or funds in an Account to the extent prohibited by law. If the law imposes conditions or limits on our ability to take or set off funds in your Accounts, to the extent that you may do so by contract, you waive those conditions and limits, and you authorize us to apply funds as we deem applicable. We may also exercise these rights against a joint Account owner. These rights exist no matter who contributed the funds to the joint Account. We will consider this Agreement as your consent for us asserting our security interest or exercising our right of set-off should any laws governing your Account require your consent.</p>
<p><strong>3. No Assignment</strong></p>
<p>Your Account is not transferable and is not assignable as collateral for a loan by another party to you or for any other purpose.</p>
<p><strong>4. Ordinary Care</strong></p>
<p>You agree that any act or omission made by us in reliance upon or in accordance with any provision of the Uniform Commercial Code as adopted in the State of Utah, or any rule or regulation of the State of Utah or a federal agency having jurisdiction over the Bank, shall constitute ordinary care.</p>
<p><strong>5. Indemnification and Limitation of Liability</strong></p>
<p>You agree to indemnify and hold us harmless from any losses, damages, suits, costs and expenses, including reasonable attorneys’ fees, which we may incur as a result of taking any action or not taking any action that we are entitled to take pursuant to this Agreement or relying upon instructions or information from you.</p>
<p>EXCEPT AS OTHERWISE REQUIRED BY APPLICABLE LAW, WE ARE NOT LIABLE FOR ANY CLAIMS, COSTS, LOSSES, OR DAMAGES RESULTING DIRECTLY OR INDIRECTLY FROM OUR FAILURE TO ACT, OR ANY DELAY BEYOND TIME LIMITS PRESCRIBED BY LAW OR PERMITTED BY THIS AGREEMENT IF SUCH FAILURE OR DELAY IS CAUSED BY YOUR NEGLIGENCE, ACTS OR OMISSIONS OF THIRD PARTIES, INTERRUPTION OR MALFUNCTION OF EQUIPMENT OR COMMUNICATION FACILITIES, SUSPENSION OF PAYMENTS BY ANOTHER FINANCIAL INSTITUTION, FIRE, NATURAL DISASTERS, ELEMENTS OF NATURE, GOVERNMENT ACTION, ACTS OF WAR, TERRORISM OR CIVIL STRIFE, EMERGENCY CONDITIONS, OR OTHER CIRCUMSTANCES BEYOND THE REASONABLE CONTROL OF THE BANK, PROVIDED THE BANK EXERCISED SUCH DILIGENCE AS THE CIRCUMSTANCES REQUIRE. EXCEPT AS OTHERWISE REQUIRED BY APPLICABLE LAW, THE BANK SHALL BE EXCUSED FROM SUCH FAILURE TO ACT OR DELAY AS LONG AS SUCH CIRCUMSTANCES PREVAIL, AND THE BANK CONTINUES TO USE ITS COMMERCIALLY REASONABLE EFFORTS TO RECOMMENCE PERFORMANCE. EXCEPT AS OTHERWISE REQUIRED BY APPLICABLE LAW, OUR LIABILITY TO YOU FOR A CLAIM IS LIMITED TO THE FACE VALUE OF THE ITEM OR TRANSACTION, OR THE ACTUAL VALUE OF ANY FUNDS NOT PROPERLY CREDITED OR DEBITED AND WE WILL NOT BE LIABLE FOR SPECIAL, INCIDENTAL, EXEMPLARY, PUNITIVE, OR CONSEQUENTIAL LOSSES OR DAMAGES OF ANY KIND.</p>
<p>This Indemnification and Limitation of Liability subsection does not, in any case, reduce our liability to you as described in the section “IX. Electronic Fund Transfer Disclosure and Terms” or reduce your liability to us as described in section “X. Wire Transfer Terms.”</p>
<p><strong>6. Notice of Negative Information</strong></p>
<p>We may report information about your Account to consumer reporting agencies, including negative information, such as information concerning insufficient funds, overdrafts, or other defaults on your Account, and this information may be reflected in your report with a consumer reporting agency.</p>
<p><strong>7. Severability; Rights Cumulative; Waiver</strong></p>
<p>If any part of this Agreement is determined to be invalid or unenforceable, under applicable law or regulation, such determination will not affect the validity or enforceability of the remainder of this Agreement. The rights of the Bank under this Agreement are cumulative of all other rights we may have by law or otherwise. We may delay or waive any rights we have under this Agreement in accordance with applicable law. Any waiver by the Bank of any rights under any provision of the Agreement or in applicable law on any occasion will not constitute a waiver of the same or any other right or provision on any other occasion.</p>
<p><strong>8. Governing Law; Choice of Forum</strong></p>
<p>All Accounts are opened at the Utah office of the Bank. All actions relating to your Account, including this Agreement, will be governed by the laws and regulations of the United States and the State of Utah (to the extent that Utah law is not preempted by federal law). Federal and Utah law shall be applied without giving effect to principles of conflicts of law.</p>
<p>To the extent any dispute arising under this Agreement or relating in any way to your Account or your relationship with the Bank is not arbitrated, you consent to the jurisdiction of, and agree that such dispute will be resolved by, the Third Judicial District Court, Salt Lake City, State of Utah, or the United States District Court for the District of Utah located in Salt Lake City, Utah.</p>
<p><strong>9. Requirements of Fiduciary Accounts</strong></p>
<p>Deposits held by a fiduciary on behalf of one or more principals are insured on a pass-through basis as the deposits of the principal (the actual owner) to the same extent as if the deposits were deposited directly by the principal, provided all of the following three requirements are met:</p>
<ul>
  <li>Funds must be in fact owned by the principal and not by the third party who set up the account (i.e., the fiduciary or custodian who is placing the funds). To confirm the actual ownership of the deposit funds, the FDIC may review:
    <ol type="a">
      <li>The agreement between the third party establishing the account and the principal</li>
      <li>The applicable state law</li>
    </ol>
  </li>
  <li>The IDI’s account records must indicate the agency nature of the account (e.g., XYZ Company as Custodian, XYZ For the benefit of (FBO), Jane Doe UTMA John Smith, Jr.)</li>
  <li>The records of the IDI, the fiduciary or a third party must indicate both the identities of the principals as well as the ownership interest in the deposit.</li>
</ul>
<p>The first requirement above will not be satisfied if the purported agent or the custodian has entered into a debtor/creditor relationship with the purported owner as opposed to an agent/principal relationship. The creation of a debtor/creditor relationship may occur if the purported agent has changed the terms of the IDI’s deposit contract, such as the terms relating to maturity dates or interest rates. For example, if a customer of a deposit broker is promised by the “agent” that he or she will earn 3% on his or her deposit when the IDI is paying only 2%, the “agent” would not be an agent but a debtor with an independent obligation to pay 3%.</p>
<p>In such a scenario, the deposits at the IDI would not be eligible for “pass-through” coverage to the customers. Rather, the deposits would be treated as corporate deposits belonging to the so-called “agent.”</p>
<p>The scenario above (in which the “agent” pays interest in excess of the interest paid by the IDI) should be contrasted with scenarios in which an agent retains part of the interest paid by the IDI as the agent’s fee. In the latter scenario, “pass-through” coverage is possible because the agent does not assume independent debt obligations. Of course, the agent should disclose the existence of all such fees to its customers.</p>

<h2 class="section-title">VIII. FUNDS AVAILABILITY</h2>
<p><strong>1. Delayed Availability</strong></p>
<p>We may delay the availability of funds from certain types of deposits for all Accounts. There are exceptions that may apply in certain circumstances and special rules that apply to a customer who opened their account within 30 days (“New Customer”). During the delay, you may not withdraw funds and may not use the funds. In accordance with the provisions in this Agreement, interest will be paid on deposits we receive, even if the funds are not yet available for withdrawal.</p>
<p><strong>2. Day of Deposit</strong></p>
<p>If we receive a deposit before 3:00 PM MT on a Business Day, we will consider that Business Day to be the day of your deposit. However, if we receive a deposit after 3:00 PM MT or on a non-Business Day, we will consider that the deposit was made on the next Business Day.</p>
<p><strong>3. Availability of Wire Transfer Deposits</strong></p>
<p>Funds from wire transfers to your Account that you initiate through another bank will be available on the Business Day we receive the deposit.</p>
<p><strong>4. Availability of ACH Transfer Deposits:</strong></p>
<ol type="a">
  <li>Funds from ACH Transfers that you initiate through another bank will generally be available on the next Business Day after the day the ACH Transfer is deemed to be received by us but may be available earlier, depending on the type of ACH Transfer.</li>
  <li>Funds from ACH Transfers that you initiate through the Bank from a linked external account will generally be available on the tenth Business Day after the Business Day on which the ACH Transfer is received by us.</li>
</ol>
<p><strong>5. Availability of Internal Transfers Between Accounts at the Bank</strong></p>
<p>When you transfer funds from one of your Accounts at the Bank to another of your Accounts at the Bank on a Business Day, the funds will be available immediately.</p>
<p><strong>6. Availability of Check Deposits</strong></p>
<p>WebBank does not accept any checks for deposit. All deposits must be via ACH or Wire Transfer. All checks received will be returned to the sender. You agree to be responsible for any delay or loss associated with checks delivered to and returned by the Bank.</p>

<h2 class="section-title">IX. ELECTRONIC FUND TRANSFER DISCLOSURE AND TERMS</h2>
<p><strong>1. Electronic Fund Transfer Disclosure and Terms Coverage</strong></p>
<p>Because your Account was established for personal, family, or household purposes, it is governed by the Electronic Fund Transfer Act and Regulation E. The following disclosures and terms apply to Electronic Fund Transfers. For purposes of this Agreement, the term “Electronic Fund Transfer” refers to ACH Transfers (including direct deposits) and internal transfers to or from another Account at the Bank. To the extent other terms in this Agreement conflict with this Electronic Fund Transfer Disclosure and Terms section, as applied to Electronic Fund Transfers, the provisions of this section shall govern. See section “V. Deposits and Withdrawals” for information on the types of Electronic Fund Transfers that you can make and the limitations.</p>
<p><strong>2. Account Statements and Electronic Fund Transfers</strong></p>
<p>Your periodic Account statement will show the date, amount, and description of each Electronic Fund Transfer. For additional information on your periodic Account statement, see subsection “VI. Your Account; 4. Account Statements.” You may verify posting of an Electronic Fund Transfer on the next Business Day after the Electronic Fund Transfer is scheduled to be made by calling us or logging onto our website.</p>
<p><strong>3. Notice of Varying Amounts in Electronic Fund Transfers</strong></p>
<p>If you have recurring Electronic Fund Transfers made to your Account at least once every sixty (60) days from the same person or entity set up through another bank or third party and the amounts may vary, the person initiating such transfer is responsible to tell you, ten (10) days before each transfer, when it will be made available and how much it will be. In addition, you can call our Customer Support department at 1-844-994-BANK (2265) 8:00 AM to 5:00 PM MT or access Online Banking to find out whether or not a recurring deposit has been made.</p>
<p><strong>4. Stopping Electronic Fund Transfers:</strong></p>
<ol type="a">
  <li>If you have scheduled a one-time Electronic Fund Transfer or a preauthorized or recurring Electronic Fund Transfer through the Bank, you can stop any of these transfers if we receive your request in time. If you schedule a future transfer (not a same-day transfer) through Online Banking (as defined in section “XII. Online Banking”), you may cancel the transfer by calling our Customer Support at 1-844-994-BANK (2265) no later than three (3) Business Days before the transfer was scheduled. Written requests must be sent to WebBank, Attn: Deposit Operations, 215 South State Street, Suite 1000, Salt Lake City, Utah 84111, and must be received within fourteen (14) days after you call. If you order us to stop a payment three (3) Business Days or more before the transfer is scheduled and we do not do so, we will be liable for the losses or damages.</li>
  <li>If you have scheduled a one-time Electronic Fund Transfer or a preauthorized or recurring Electronic Fund Transfer through an external bank or with a third party, we will honor a request from you to stop the transfer if we receive the request at least three (3) Business Days before the scheduled date of the Electronic Fund Transfer. You may request to stop the transfer by calling our Customer Support at 1-844-994-BANK (2265) or sending us a written request to WebBank, Attn: Deposit Operations, 215 South State Street, Suite 1000, Salt Lake City, Utah 84111.</li>
</ol>
<p><strong>5. Confidentiality – Information We Collect About You</strong></p>
<p>You authorize us to use information we have about you and share that information with our affiliates and third parties, except as provided by law or our Privacy Notice. For details about how we treat information we have about you and your rights and choices, consult our Privacy Notice.</p>
<p><strong>6. Liability for Unauthorized Electronic Fund Transfers from Your Account</strong></p>
<p>Tell us AT ONCE if you believe that your Credentials have been lost or stolen or that an Electronic Fund Transfer has been made from your Account without your permission. Contacting us by telephone is the best way to minimize your possible losses. If you tell us within two (2) Business Days after you learn that your Credentials have been lost or stolen or of the unauthorized transfer of funds, your liability will be no more than $50.</p>
<p>If you DO NOT tell us within two (2) Business Days after you learn that your Credentials have been lost or stolen or that an unauthorized transfer of funds has occurred, and we can prove that we could have stopped the unauthorized transfer if you told us, you could lose as much as $500.</p>
<p>If your statement shows Electronic Fund Transfers that you did not make, you need to notify us at once. If you do not notify us within sixty (60) days after the Account statement was sent or made available to you, you may not get back the money you lost after the sixty (60) days if we can prove that we could have stopped someone from taking the money if you had notified us in time. If a good reason (such as a long trip or a hospital stay) kept you from notifying us, we may extend the time periods.</p>
<p><strong>7. The Bank’s Liability for Failure to Complete Electronic Fund Transfers</strong></p>
<p>If we do not complete an Electronic Fund Transfer to or from your Account on time or in the correct amount according to this Agreement, we will be liable for your losses or damages. However, there are some exceptions. We will not be liable, for instance:</p>
<ul>
  <li>If, through no fault of ours, you do not have sufficient available funds in your Account to make the transfer;</li>
  <li>If you have met or exceeded applicable withdrawal limits during the monthly statement period for your Account;</li>
  <li>If you did not provide us with the correct information to make the transfer;</li>
  <li>If there was an equipment or website malfunction of which you were aware before you sent us the instruction;</li>
  <li>If circumstances beyond our control (such as power failure, fire, or flood) prevent the transfer from occurring, despite reasonable precautions we have taken; or</li>
  <li>If your Account has been closed</li>
</ul>
<p>There may also be other exceptions not specifically mentioned in this Agreement or that are stated elsewhere in this Agreement that may apply.</p>
<p><strong>8. Error Resolution or Questions Regarding Electronic Fund Transfers</strong></p>
<p>Call or write to us at the number or address listed in the “Bank Contacts for Electronic Fund Transfers” section below as soon as you can, if you think your Account statement or receipt is wrong or if you need more information about a transfer listed on the statement or receipt. We must hear from you no later than sixty (60) days after we sent or made available to you the first Account statement on which the problem or error appeared. You must:</p>
<ul>
  <li>Tell us your name and Account number;</li>
  <li>Describe the error or transfer you are unsure about, and explain as clearly as you can why you believe it is an error or why you need more information; and</li>
  <li>Tell us the dollar amount of the suspected error.</li>
</ul>
<p>If you tell us orally, we may require that you send your question or complaint to us in writing within ten (10) Business Days.</p>
<p>We will determine whether an error occurred within ten (10) Business Days after we hear from you and will correct any error within one (1) Business Day after determining that an error occurred. If we need more time, however, we may take up to forty- five (45) days to investigate your question or complaint. If we need additional time to investigate, we will provide a provisional credit to your Account within ten (10) Business Days for the amount you think is in error so that you will have the use of the money during the time it takes us to complete our investigation. If we ask you to put your question or complaint in writing and we do not receive it within ten (10) Business Days, we may not credit your Account.</p>
<p>For errors involving New Customers, we may take up to ninety (90) days to investigate your complaint or question. For New Customers, we may take up to twenty (20) Business Days to credit your Account for the amount you think is in error. We will notify you of the results within three (3) Business Days after we complete our investigation. If we determine that there was no error, we will send you a written explanation and may reverse any credit provided. You may ask for copies of the documents that we used in our investigation.</p>
<p>Error Resolution Time Frames are:
<ul>
  <li>Communication to customers will occur within two business days after granting any provisional credit;</li>
  <li>The EFT error will be corrected within one business day after determining that an error occurred;</li>
  <li>The results of the investigation will be reported to the consumer within three business days after completing the investigation including, if applicable, notice that provisional credit has been made final; and</li>
  <li>The Bank will send a written explanation of its findings to the customer and note the customer’s right to request the documents the financial institution used in making its determination.</li>
</ul>
</p>
<p><strong>9. Bank Contacts for Electronic Fund Transfers</strong></p>
<p>If you need to reach us about your Account and/or in the event of an unauthorized transfer, use the following telephone number or address:</p>
<p>Customer Support: 1-844-994-BANK (2265)—Business Days, 8:00 AM to 5:00 PM MT</p>
<p>Notices and General Mail: WebBank, Attn: Deposit Operations, 215 South State Street, Suite 1000, Salt Lake City, Utah 84111</p>

<h2 class="section-title">X. WIRE TRANSFER TERMS</h2>
<p><strong>1. Coverage of Wire Transfer Terms</strong></p>
<p>In addition to the other applicable terms in this Agreement, these Wire Transfer Terms govern the transfer of funds to or from your Accounts by wire transfer and applicable fees. To the extent other terms in this Agreement conflict with this Wire Transfer Terms section, as applied to wire transfers, the provision of this Wire Transfer Terms section shall govern.</p>
<p><strong>2. Wire Transfer Authorization</strong></p>
<p>By giving us an instruction (known as a “payment order”) you authorize us to debit your Account you designate for the amount of the wire transfer request. Your payment order may instruct that we wire transfer funds from your Account to your external account at another bank.</p>
<p><strong>3. Wire Transfer Payment Orders</strong></p>
<p>Payment orders must be given to us in accordance with the cut-off times established. It may take one (1) to three (3) Business Days to process your wire transfer request. All payment orders must be received by us before 1:00 PM MT on a Business Day, otherwise the payment order shall be considered received on the next Business Day. You generally cannot cancel or amend a payment order after we have received it, except as otherwise provided by an applicable fund transfer system rule. We reserve the right to process payment orders in the order in which we determine, in our sole discretion. We are not obligated to accept or execute any payment orders.</p>
<p><strong>4. Information You Provide for Wire Transfers:</strong>
<ol type="a">
  <li>Each payment order you provide to us must contain all of the information needed to complete the wire transfer. We have no obligation or responsibility to determine if the payment order is accurate or to detect errors contained in a payment order. You acknowledge that the authentication and security procedure (described below) is not designed to allow us to determine accuracy or to detect errors.</li>
  <li>You are solely responsible for correctly identifying the external account and bank that you are transferring funds to in your payment order. If you have told us to route funds through an intermediary bank, you are solely responsible for correctly providing the intermediary bank’s routing number. If the intermediary bank has suspended payment orders, you are still responsible for the payment order.</li>
</ol>
</p>
<p><strong>5. Rejection of Wire Transfers</strong></p>
<p>We will use our reasonable efforts to contact you if any payment order is rejected or if there is a delay or non-credit of an incoming wire transfer. We shall not be liable to you for interest compensation as a result of our failure to give such notice. We have a right to reject any payment order for reasons including, but not limited to, insufficient or uncollected funds in your designated Account, a request that fails the security procedures described in Section 7 below, or our inability to execute the payment order for reasons outside our control.</p>
<p><strong>6. When Giving Us a Wire Transfer Payment Order</strong></p>
<p>You understand and agree:
  <ol type="a">
    <li>Generally, we will use the Federal Reserve Banks’ wire transfer system, but you authorize us to use any wire transfer system we deem appropriate in our sole discretion. You acknowledge that each wire transfer system is governed by its own operating rules and regulations and you agree that each payment order shall be subject to the rules and regulations of the wire transfer system, including the adjustments of errors between banks. The Bank shall not be liable for any errors, negligence, suspension or default of any such system and we shall not be liable for any delay or failure of delivery in the transmission of a payment order through any such system.</li>
    <li>You have not communicated to us any restrictions and/or limitations whatsoever relevant to payment orders, and no such restrictions and/or limitations shall be binding upon us, unless we have previously agreed to them in writing.</li>
    <li>You agree to observe all Anti-Money Laundering and other U.S. laws and regulations, including but not limited to economic and trade sanctions promulgated by OFAC in relation to any payment order, and to assist us to do. You agree to provide any additional information, either in respect of individuals, entities, or particular transactions that we may request from you promptly. If you breach any such laws or regulations, you agree that we may retain any monies or funds transmitted to us and/or not execute and payment order if we are required to take or refrain from such action by any legal or regulatory rule, by instruction of a governmental authority or if we reasonably believe that such action may violate any applicable laws or regulations. You agree that we are not responsible for any interest payment on such funds arising from such actions and you further agree that we may pay such funds to the appropriate legal, regulatory, or governmental authority as may be required by applicable rules.</li>
  </ol>
</p>
<p><strong>7. Wire Transfer Authentication and Security Procedure</strong></p>
<p>You agree that the following security procedures, if undertaken by the Bank, are a commercially reasonable method of providing security against unauthorized wire transfers and meet your security requirements.</p>
<ol type="a">
  <li>Prior to accepting or executing a payment order, we will authenticate the identity of the requestor, confirm ownership of the external account, and verify authorization of the payment order through the use of previously established security procedures described below. If we are unable to complete the required authentications, confirmations, and security procedures, or are not satisfied with the information received, the payment order will not be accepted or executed, and we shall have no liability.</li>
  <li>To verify authorization of the payment order, we may initiate a callback process in which the person giving the payment order will be required to confirm the information in the payment order and to provide the correct answer to one or more security questions and/or passwords.</li>
  <li>You agree that we may record and retain all telephone conversations, data transmissions, or other communications between you and us relating to the security procedures by any reasonable means. We may retain such recorded communications and may use the recorded communications for the purpose of demonstrating that the communications occurred, and to prove that a security procedure was used in connection with the acceptance and execution of a payment order or that the Bank has otherwise complied with the terms of these Wire Transfer Terms.</li>
  <li>Upon completion of the security procedure, we are authorized and directed to execute, pay, and act upon the payment order, without the need to do any further inquiry, and to debit the Account from which the funds are to be transferred. Except as otherwise provided by applicable law, you agree that payment orders received by us are effective as the payment order made by you, and you shall be obligated to pay us the amount of the payment order, whether or not authorized, if we accepted the payment order in good faith and in compliance with the above security procedures.</li>
  <li>You agree to prevent disclosure of the security questions and/or passwords and other confidential aspects of the security procedures, except on a “need to know” basis. You will notify us immediately if the confidentiality of the security procedures is compromised and will take steps to prevent the security procedures from being further compromised.</li>
</ol>
<p><strong>8. Account Statements and Wire Transfers</strong></p>
<p>You agree to examine your periodic Account statements promptly upon receipt and to notify us immediately of any error or discrepancy in the statement. We shall not be liable for interest compensation unless we are notified of an error or discrepancy within fourteen (14) days from the date of the statement identifying a payment order. Failure to provide timely notice shall preclude you from asserting any claim against the Bank with respect to a payment order, or from otherwise objecting to any debit to your Account.</p>
<p><strong>9. Wire Transfer Liability Provisions</strong></p>
<p>THE BANK’S LIABILITY FOR ANY WIRE TRANSFER IS LIMITED TO THE AMOUNT OF ANY PAYMENT ORDER LOSS PLUS, IF APPLICABLE, INCIDENTAL EXPENSES AND INTEREST. IN NO EVENT WILL THE BANK BE LIABLE FOR ANY SPECIAL, CONSEQUENTIAL, OR INDIRECT LOSS OR DAMAGE IN CONNECTION WITH THESE WIRE TRANSFER TERMS, REGARDLESS OF WHETHER WE KNEW OR SHOULD HAVE KNOWN SUCH LOSS OR DAMAGE MIGHT BE INCURRED.</p>
<p><strong>10. Wire Transfer Indemnification Provisions</strong></p>
<p>Except as may be limited by applicable law, you agree to indemnify us and to hold us harmless from and against each and all of the following (the “Liabilities”): (a) any losses, damages, costs and expenses incurred by us in executing a payment order or its performance of the terms and conditions of the Wire Transfer Terms; (b) all liability to third parties arising out of, or in connection with, the terms and conditions of the Wire Transfer Terms and/or our execution, delay in execution or non-execution of any payment order (including, without limitation, delays associated with the Bank Secrecy Act or any other federal law or regulation); (c) any and all liability, claims/causes of action, lawsuits, proceedings, fines and/or penalties arising out of our execution or delay or non-execution of any payment order or the delay or non-payment/credit of an incoming wire transfer, or otherwise performing any duties or obligations arising under the Wire Transfer Terms; and (d) any damages, losses, costs and expenses (including reasonable attorneys’ fees) suffered or incurred by us as a result of any of the foregoing or because of any breach of the terms and conditions of the Wire Transfer Terms by you. You authorize us to set off the amount of any of the liabilities against any of your Accounts at the Bank without prior notice to you or your consent.</p>

<h2 class="section-title">XI. DISPUTE RESOLUTION; AGREEMENT TO ARBITRATE; CLASS ACTION WAIVER</h2>
<p>Many concerns can be resolved by calling us at 1-844-994-BANK (2265) — Business Days, 8:00 AM to 5:00 PM MT or writing us at WebBank, Attn: Deposit Operations, 215 South State Street, Suite 1000, Salt Lake City, Utah 84111. If a dispute cannot be resolved informally, this Section XI explains how claims (whether by you against us, or by us against you) will be resolved.</p>
<p><strong>1. Definitions</strong></p>
<p>For purposes of this Section XI, “Claim” means any current or future claim, dispute or controversy relating in any way to this Agreement or your Accounts, except for the validity, enforceability or scope of the arbitration provision set forth in subsection XI.4. below. “Claim” includes: (i) initial claims, counterclaims, cross claims and third-party claims; (ii) claims based upon contract, tort, fraud, statute, regulation, common law and equity; (iii) claims by or against any third party using or providing any product, service or benefit in connection with this Agreement or your Accounts; and (iv) claims that arise from or relate to (A) this Agreement, (B) your Accounts, (C) advertisements, promotions, or statements related to this Agreement or your Accounts, (D) your application to open an Account, or (E) consumer reporting or inquiries related to your Account.</p>
<p><strong>2. Claim Notices</strong></p>
<p>Before beginning a lawsuit, mediation or arbitration, you and we agree to send a notice (a “Claim Notice”) to each party against whom a Claim is asserted. The Claim Notice will give you and us a chance to resolve our dispute informally or in mediation. The Claim Notice must describe the Claim and state the specific relief demanded. Notice to you may be sent to your current mailing address or email address on file with the Bank. You must provide your name, address and phone number in your Claim Notice. Your Claim Notice must be sent to WebBank, Attn: Deposit Operations, 215 South State Street, Suite 1000, Salt Lake City, Utah 84111.</p>
<p><strong>3. Mediation</strong></p>
<p>In mediation, a neutral mediator helps parties resolve a Claim. The mediator does not decide the Claim but helps parties reach agreement. Before beginning mediation, you or we must first send a Claim Notice. Within thirty (30) days after sending or receiving a Claim Notice, you or we may submit the Claim to JAMS (1-800-352-5267, jamsadr.com) or AAA (1-800-778-7879, adr.org) for mediation. We will pay the fees of the mediator. All mediation-related communications are confidential, inadmissible in court and not subject to discovery. All applicable statutes of limitation will be tolled until termination of the mediation. Either you or we may terminate the mediation at any time. The submission or failure to submit a Claim to mediation will not affect your or our right to elect arbitration.</p>
<p><strong>4. Arbitration</strong></p>
<ol type="a">
  <li>You or we may elect to resolve any Claim by individual binding arbitration. This election may be made by the party asserting the Claim or the party defending the Claim. Claims will be decided by one neutral arbitrator who will be a retired judicial officer or an attorney with at least ten (10) years of experience; however, if we both agree, we may select another person with different qualifications.</li>
  <li>IF ARBITRATION IS CHOSEN BY ANY PARTY, NEITHER YOU NOR WE WILL HAVE THE RIGHT TO LITIGATE THAT CLAIM IN COURT OR HAVE A JURY TRIAL ON THAT CLAIM. FURTHER, YOU AND WE WILL NOT HAVE THE RIGHT TO PARTICIPATE IN A REPRESENTATIVE CAPACITY OR AS A MEMBER OF ANY CLASS PERTAINING TO THAT CLAIM. ARBITRATION PROCEDURES ARE GENERALLY SIMPLER THAN THE RULES THAT APPLY IN COURT, AND DISCOVERY IS MORE LIMITED. THE ARBITRATOR’S DECISIONS ARE AS ENFORCEABLE AS ANY COURT ORDER AND ARE SUBJECT TO VERY LIMITED REVIEW BY A COURT. THE ARBITRATOR’S DECISION WILL BE FINAL AND BINDING. OTHER RIGHTS YOU OR WE WOULD HAVE IN COURT MAY ALSO NOT BE AVAILABLE IN ARBITRATION.</li>
  <li>Before beginning arbitration, you or we must first send a Claim Notice. The party electing arbitration must choose to arbitrate before either JAMS or AAA. However, if we choose one of those two organizations, you may choose the other organization instead if you inform us of that choice within thirty (30) days after we elect arbitration.</li>
  <li>Claims will be resolved pursuant to this section XI and the selected organization’s rules in effect when the Claim is filed, except where those rules conflict with this section XI. If the rules conflict, the terms of this Agreement will apply. Contact JAMS or AAA to begin an arbitration or for other information. Claims also may be referred to another arbitration organization, if you and we agree in writing, or to an arbitrator appointed pursuant to Section 5 of the Federal Arbitration Act, 9 U.S.C. §§ 1-16 (the “FAA”).</li>
  <li>We will not elect arbitration for any Claim you file in small claims court, so long as the Claim is individual and pending only in that court. You or we may otherwise elect to arbitrate any Claim at any time unless it has been filed in court and trial has begun or unless final judgment has been entered. Either you or we may delay enforcing or not exercise rights under this arbitration provision, including the right to arbitrate a Claim, without waiving the right to exercise or enforce those rights.</li>
  <li>This arbitration provision is governed by the FAA. The arbitrator will apply applicable substantive law, statutes of limitations and privileges. The arbitrator will not apply any federal or state rules of civil procedure or evidence in matters relating to evidence or discovery. Subject to the limitations of this section XI, the arbitrator may award any relief available in court. Any award of punitive damages will be subject to the same limitations as an award of punitive damages in court. The arbitration will be confidential, but you may notify any government authority of your Claim. Judgment upon any arbitration award may be entered in any court having jurisdiction. Arbitration hearings will take place in Salt Lake County, Utah or, upon your request, in the federal judicial district where you reside.</li>
  <li>You will be responsible for paying your share of any arbitration fees (including filing, administrative, hearing or other fees), but only up to the amount of the filing fees you would have incurred if you had litigated in court. We will be responsible for any additional arbitration fees. We will consider in good faith making a temporary advance of your share of any arbitration fees or paying for the reasonable fees of an expert appointed by the arbitrator for good cause, if you ask us in writing to do so.</li>
</ol>
<p><strong>5. Arbitration Opt-Out; Your Right to Reject Arbitration</strong></p>
<p>YOU MAY REJECT THIS ARBITRATION PROVISION BY CALLING us at 1-844-994-BANK (2265)—Business Days, 8:00 AM to 5:00 PM MT or writing us at WebBank, Attn: Deposit Operations, 215 South State Street, Suite 1000, Salt Lake City, Utah 84111 AND STATING THE FOLLOWING IN YOUR NOTICE: (I) YOUR NAME, (II) YOUR ADDRESS, (III) YOUR PHONE NUMBER, AND (IV) THAT YOU ARE EXERCISING YOUR RIGHT TO REJECT ARBITRATION UNDER SUBSECTION XI.5. OF YOUR AGREEMENT (A “REJECTION NOTICE”). YOUR REJECTION NOTICE MUST BE RECEIVED WITHIN NINETY (90) DAYS AFTER THE OPENING OF YOUR ACCOUNT. IF YOUR REJECTION NOTICE COMPLIES WITH THESE REQUIREMENTS, THE ARBITRATION PROVISION IN SUBSECTION XI.4. WILL NOT APPLY TO YOU, EXCEPT FOR ANY CLAIMS SUBJECT TO PENDING LITIGATION OR ARBITRATION AT THE TIME YOU SEND YOUR REJECTION NOTICE. REJECTION OF THE ARBITRATION PROVISION WILL NOT AFFECT YOUR OTHER RIGHTS OR RESPONSIBILITIES UNDER THIS SECTION XI OR THIS AGREEMENT.</p>
<p><strong>6. Class Action Waiver and Other Limitations on Arbitration</strong></p>
<p>IF EITHER PARTY ELECTS TO RESOLVE A CLAIM BY ARBITRATION, THAT CLAIM WILL BE ARBITRATED ON AN INDIVIDUAL BASIS. THERE WILL BE NO RIGHT OR AUTHORITY FOR ANY CLAIMS TO BE ARBITRATED ON A CLASS ACTION BASIS, IN A PURPORTED REPRESENTATIVE CAPACITY OR ON BEHALF OF THE GENERAL PUBLIC, OTHER ACCOUNT OWNERS OR OTHER PERSONS. The arbitrator’s authority is limited to Claims between you and us alone. Claims may not be joined or consolidated unless you and we agree in writing. Any arbitration award shall be confidential, and any judgment confirming it will apply only to the specific case and cannot be used in any other case except to enforce the award. Despite any other provision and without waiving the right to appeal such decision, if any portion of this subsection XI.6. is deemed invalid or unenforceable, then the entire arbitration provision set forth in subsection XI.6. will not apply.</p>
<p><strong>7. Survival and Severability of Dispute Resolution Provision</strong></p>
<p>This section XI will survive the closing of your Account and the termination of any relationship between us, termination of this Agreement, any legal proceeding relating to your Accounts, any bankruptcy (as applicable) or receivership and any sale or assumption of this Agreement, your Account or any and all rights thereunder. In the case of a sale or assumption of this Agreement, your Account or any and all rights thereunder, the buyer will be bound by and may enforce the terms of this section XI. If any portion of this section XI is deemed invalid or unenforceable, it will not invalidate the remaining provisions of this section XI or of this Agreement (except as set forth in subsection XI.6.).</p>
<h2 class="section-title">XII. ONLINE BANKING</h2>
<p><strong>1. Online Banking</strong></p>
<p>This section XII, as well as the other applicable portions of the Agreement, apply to your use of the portion of our website that is accessed after logging in with your Credentials and is used to access your Account, transfer funds to and from your Account, and perform other actions related to your Account (“Online Banking”). This section XII only governs your use of Online Banking with respect to your Account and does not govern your use of our website to access any other non-Account products.</p>
<p><strong>2. Relation to Other Agreements</strong></p>
<p>By using Online Banking or permitting any other person to use Online Banking on your behalf, you are agreeing to the terms and conditions in the Agreement, including but not limited to this Section XII. In addition to this Agreement, your use of Online Banking is also governed by the eSign Agreement and the WebBank Terms of Use (the “Site Terms”). If this section XII conflicts with any other provisions of this Agreement or the eSign Agreement, unless specifically stated otherwise in the other agreements, the other agreements will control, to the extent necessary, to resolve any conflict or inconsistency between this Agreement and the other agreements. If this section XII conflicts with the Site Terms, this section XII shall control.</p>
<p><strong>3. Access Requirements</strong></p>
<p>To access Online Banking through our website you will need: (i) and active Account at the Bank; (ii) a working connection to the Internet via a web browser on your computer or mobile device that is compatible with the latest version of our website; (iii) a valid email address; (iv) your log in Credentials for our website; (v) to have consented to the eSign Agreement and agreed to the Site Terms on our website; and (vi) any other information specified on our website when you log in to our website. In the case of a joint Account, each joint owner must have his/her own valid email address and log in Credentials for our website and must separately consent to the eSign Agreement and agree to the Site Terms posted on our website.</p>
<p><strong>4. Cancelling Access to Online Banking</strong></p>
<p>You may cancel your access to Online Banking by calling Customer Support. If you cancel your access to Online Banking, we will close your Accounts and return all funds to the linked account(s) from which the original funding was received. If any of your Accounts are TD Accounts, the applicable early withdrawal penalty will be imposed.</p>
<p><strong>5. Termination or Suspension of Online Banking</strong></p>
<p>If you close your Account(s) with us or withdraw your consent to the eSign Agreement, you will no longer have access to Online Banking and we will not be able to provide required notices and documentation to you. We will close your Accounts and return all funds to the linked account(s) from which the original funding was received. In certain circumstances, we may send you a bank check at our sole discretion.</p>
<p>We may suspend, terminate, or limit your use of Online Banking for any reason and without notice except as may be required by law. If we suspend, terminate or limit your use of Online Banking, any scheduled transactions you established through Online Banking may not be completed. Our suspension, termination, or limitation of access to Online Banking will not affect any obligations or liabilities you may have under this Agreement. If your Account is placed in an inactive (dormant) status, we may suspend your access to Online Banking. Online Banking may be unavailable during regularly scheduled or other system maintenance and upgrades, as a security precaution, or when interrupted by circumstances beyond our control. Online Banking is not intended for use by persons in any jurisdiction or country where such use would be contrary to applicable laws or regulations. We may restrict Online Banking during times you are in a country for which transactions using Online Banking would be prohibited or as a security precaution.</p>
<p><strong>6. Using Online Banking</strong></p>
<p>You may use Online Banking to perform the following functions:
  <ul>
    <li>View Account balances and transaction history;</li>
    <li>Request internal transfers of funds between your Accounts at the Bank;</li>
    <li>View Account statements;</li>
    <li>Link your Account with your accounts at external banks and request transfers of funds between your Account and your linked accounts at external banks through ACH Transfers;</li>
    <li>Open and fund additional Accounts with us; and</li>
    <li>Utilize additional features that we may add.</li>
  </ul>
</p>
<p>You agree that we may from time-to-time add more features and functionality or remove features or functionality without notice.</p>
<p>As a condition of using Online Banking, you represent and warrant to us that you will not use Online Banking for any purpose that is unlawful or is not permitted, expressly or implicitly, by the terms of this Agreement or by any applicable law or regulation. Further, you will use Online Banking only for the benefit of your Account and not on behalf of any other party.</p>
<p><strong>7. ACH Transfers Through Online Banking</strong></p>
<p>Requests for ACH Transfers or internal transfers between Accounts at the Bank that are made through Online Banking before 3:00 PM MT on a Business Day shall be considered received on the Business Day the request is made. Requests for ACH Transfers or internal transfers between Accounts at the Bank that are made through Online Banking after 3:00 PM MT on a Business Day or on a non-Business Day shall be considered received the next Business Day.</p>
<p><strong>8. Account Statements Available Through Online Banking</strong></p>
<p>Your Account statement will be accessible to you through Online Banking for a maximum of eighteen (18) months. If you need older Account statements, you may request them by contacting Customer Support.</p>
<p><strong>9. Service Alerts</strong></p>
<p>From time to time, we may provide automatic Account alerts. Automatic Account alerts may be sent to you regarding certain Account activities or when certain changes are made to your Account, such as a change in your email or mailing address, telephone number, or password. If we choose to send these alerts, you do not need to activate these alerts; they are automatically activated for you.</p>
<p>Automatic Account alerts that we choose to send are subject to the following:
  <ul>
    <li>From time-to-time, we may add new automatic Account alerts and cancel old alerts.</li>
    <li>You understand and agree that your alerts may be delayed or prevented by a variety of factors. We will make reasonable efforts to provide alerts in a timely manner with accurate information; however, we do not guarantee the delivery or the accuracy of the contents of any alert. You also agree that we shall not be liable for any delays, failures to deliver, or misdirected deliveries of any alert; or for any errors in the content of an alert; or for any actions taken or not taken by you or any third party in reliance on an alert. The Bank will not include your password or full Account number in an alert. However, alerts may include your name and some information about your Account(s). Anyone with access to your email will be able to view the contents of these alerts.</li>
  </ul>
</p>
<p><strong>10. Liability for Service Interruptions</strong></p>
<p>From time-to-time, due to maintenance, malfunctions or failures of software, equipment, or telecommunications devices, as well as unusual transaction volume or similar reasons, Online Banking may not be available to you. We strive to minimize the periods of time during which Online Banking is unavailable. Online Banking may also be interrupted as a result of things beyond our reasonable control, computer failures, loss of power, failure or interruption of communication or transportation facilities, riots, strikes, or civil unrest. You agree that we shall not be responsible for any loss, damages, costs or expenses that you may suffer or incur, directly or indirectly, as a result of the unavailability of Online Banking, regardless of whether it could be shown that we could have prevented or reduced the duration of such unavailability by taking any action within our reasonable control. We shall also not be responsible for any loss, damages, costs or expenses which you may suffer or incur, directly or indirectly, as a result of your inability to access Online Banking caused directly or indirectly, in whole or in part, by your computer or mobile device, your inability to establish a connection to or to access the Internet, or other limitations or constraints of the Internet. The provisions set forth in this section shall apply in addition to the other liability provisions in the Agreement but as applied to service interruptions for Online Banking, this section shall control.</p>
<p>LIMITATION OF OUR LIABILITY TO YOU: UNLESS OTHERWISE REQUIRED BY LAW, WE WILL NOT BE LIABLE TO YOU FOR LOSSES OR DAMAGES THAT YOU INCUR FROM USING ONLINE BANKING, INCLUDING DIRECT, INDIRECT, SPECIAL, PUNITIVE, OR CONSEQUENTIAL LOSSES OR DAMAGES REGARDLESS OF THE FORESEEABILITY OF THE LOSSES OR DAMAGES OR WHETHER WE WERE ADVISED OF THE LIKELIHOOD OF LOSSES OR DAMAGES.</p>
<p><strong>11. Protecting Your Accounts</strong></p>
<p>You agree not to give or make available to any unauthorized individuals your Credentials that enable access to Online Banking. If you give someone your Credentials, you are authorizing that person to use Online Banking, and you are responsible for all transactions the person performs using Online Banking. All transactions performed by a person to whom you give your Credentials, even those transactions you did not intend or want performed, are authorized transactions. If you give someone your Credentials, transactions that you or someone acting for you initiates with fraudulent intent are also authorized transactions.</p>
<p>You are solely responsible for keeping your Credentials and other online Account information secure and confidential. To protect the security and confidentiality of your Credentials, we suggest the following:
  <ul>
    <li>Do not give out to any other person your online Account information, Credentials or other means to access your Account(s);</li>
    <li>Do not leave your computer unattended while you are logged in to Online Banking;</li>
    <li>Never leave your online Account information, Credentials, or other means to access your Account within physical or viewing range of others;</li>
    <li>Do not transmit online Account information, Credentials or other means to access your Account via any public or general email system;</li>
    <li>Monitor your Account(s) for unauthorized activity; and</li>
    <li>Routinely scan your computer using a reliable virus detection and anti-spyware product.</li>
  </ul>
</p>

                </div>
              </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style scoped>
.section-title {
  font-size: 1.5rem;
  text-decoration: underline;
}
</style>
