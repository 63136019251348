<template>
    <div class="columns is-mobile">
        <div class="column is-4">
            <figure class="image">
            <img src="https://assets.webbank.com/people-laudano2.jpg" class="is-rounded" alt="Ken Laudano">
            </figure>
        </div>
        <div class="column">
            <h2 class="people-name">Ken Laudano</h2>
            <h3 class="people-title">SVP Head of Credit Structuring and Counterparty Risk</h3>
            <p class="people-bio">Ken Laudano, CAIA, FDP, is SVP - Head of Credit Structuring and Counterparty Risk at WebBank and is responsible for the credit risk management of the Bank’s Asset Finance and Strategic Partner counterparty credit exposures.  After starting his career as an analyst in the Asset Finance Group at Moody’s Investors Service, Ken has held various marketing, structuring, and credit risk management positions with some of Wall Street’s leading structured finance firms, including Morgan Stanley, WestLB, BNY Mellon, and Credit Suisse.  Ken was most recently Deputy Chief Credit Officer at East West Bank, where he was responsible for all credit decisions, oversight and strategy relating to the Bank’s Structured Finance and Private Equity NAV businesses.    He has a proven track record of creativity and discipline in managing credit risks of all types through all economic conditions.  Ken holds a B.A. in Economics from the University of Connecticut, and an M.S. in Statistics and Financial Engineering from the Stern School of Business at New York University.  He also holds the Chartered Alternative Investment Analyst (CAIA) and Financial Data Professional (FDP) designations.</p>
        </div>
    </div>
</template>

<script>
export default {

}
</script>
